const HsConnStatus = {
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  CONNECTED_NEED_AUTH: 'connectedNeedAuth' /* virtual state used only for UI */,
  CONNECTED_NEED_LOAD: 'connectedNeedLoad' /* virtual state used only for UI */,
  CONNECTED: 'connected',
  DISCONNECTING: 'disconnecting',
};

export default HsConnStatus;

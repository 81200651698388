/* Flow for performing a factory reset */

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import hsm from '../../HsManager';
import { useBleConnStatus, useHsUserActionRunning } from '../../hsHooks';
import HsConnStatus from '../../lib/HsConnStatus';
import ConfirmDialog from '../dialogs/ConfirmDialog';

const FACTORY_RESET_MSG =
  'This will delete all saved configs and recordings. ' +
  'Cloud configs will be re-synced from the cloud after reconnecting to the internet.';

function FactoryResetFlow(props) {
  const bleConnStatus = useBleConnStatus();
  const userActionRunning = useHsUserActionRunning();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusMsg, setStatusMsg] = useState('');
  const [statusMsgIsError, setStatusMsgIsError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const disabled = props.disabled || userActionRunning || bleConnStatus !== HsConnStatus.CONNECTED;

  useEffect(() => {
    function onFactoryResetStatus(factoryResetStatus) {
      if (factoryResetStatus.succeeded) {
        /* close on success */
        onCloseButton();
      } else {
        /* maintain the status of this action based on emitted events */
        setStatusMsg(factoryResetStatus.statusMsg);
        setStatusMsgIsError(!!factoryResetStatus.error);
        setSubmitButtonDisabled(factoryResetStatus.isRunning);
      }
    }

    hsm.registerEventHandler('factoryResetStatus', onFactoryResetStatus);
    return () => {
      hsm.unregisterEventHandler('factoryResetStatus', onFactoryResetStatus);
    };
  }, []);

  /* called when the user closes the dialog */
  function onCloseButton() {
    setDialogOpen(false);
    setStatusMsg('');
    setStatusMsgIsError(false);
    setSubmitButtonDisabled(false);
  }

  return (
    <>
      <Button className={props.className} disabled={disabled} variant='contained' color='secondary' onClick={() => setDialogOpen(true)}>
        Factory Reset
      </Button>
      <ConfirmDialog
        open={bleConnStatus === HsConnStatus.CONNECTED && dialogOpen}
        title='Factory Reset'
        message={FACTORY_RESET_MSG}
        submitText='Reset'
        statusMsg={statusMsg}
        statusMsgIsError={statusMsgIsError}
        disableSubmit={submitButtonDisabled}
        onClose={onCloseButton}
        onSubmit={hsm.factoryResetUI}
      />
    </>
  );
}

export default FactoryResetFlow;

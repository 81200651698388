/* Flow for setting up wifi and testing that the config works */

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import hsm from '../../HsManager';
import { useBleConnStatus, useHsUserActionRunning, useUserConfig } from '../../hsHooks';
import HsConnStatus from '../../lib/HsConnStatus';
import WifiConfigDialog from '../dialogs/WifiConfigDialog';
import InfoDialog from '../dialogs/InfoDialog';

function WifiSetupFlow(props) {
  const bleConnStatus = useBleConnStatus();
  const userConfig = useUserConfig();
  const userActionRunning = useHsUserActionRunning();
  const [wifiConfigDialogOpen, setWifiConfigDialogOpen] = useState(false);
  const [wifiSuccessDialogOpen, setWifiSuccessDialogOpen] = useState(false);
  const disabled = props.disabled || userActionRunning || bleConnStatus !== HsConnStatus.CONNECTED;

  useEffect(() => {
    /* maintain the status of the wifi setup test */
    function onSetupWifiStatus(setupWifiStatus) {
      /* close the config dialog on success and display the success dialog */
      if (setupWifiStatus.succeeded) {
        setWifiConfigDialogOpen(false);
        setWifiSuccessDialogOpen(true);
      }
    }

    hsm.registerEventHandler('setupWifiStatus', onSetupWifiStatus);
    return () => {
      hsm.unregisterEventHandler('setupWifiStatus', onSetupWifiStatus);
    };
  }, []);

  return (
    <>
      <Button
        className={props.className}
        disabled={disabled}
        variant='contained'
        color='primary'
        onClick={() => setWifiConfigDialogOpen(true)}
      >
        Setup Wifi
      </Button>
      <WifiConfigDialog
        wifiConfig={userConfig}
        open={bleConnStatus === HsConnStatus.CONNECTED && wifiConfigDialogOpen}
        onClose={() => setWifiConfigDialogOpen(false)}
        onSubmit={(wifiConfig) => hsm.setupWifiUI(wifiConfig)}
      />
      <InfoDialog
        open={bleConnStatus === HsConnStatus.CONNECTED && wifiSuccessDialogOpen}
        onClose={() => setWifiSuccessDialogOpen(false)}
        message='Wifi Connected!'
      />
    </>
  );
}

export default WifiSetupFlow;

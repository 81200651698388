/* Class representing the status of some action request */

/*
 * This class provides a simple helper interface for
 * keeping track of the status of some business logic
 * action. In general, callers should instatiate a new
 * ActionStatus() before doing any work and generate
 * status events to be emitted by calling one of:
 *
 *  - status.nextStep(): Move on to the next step of the action
 *  - status.success(): The action has succeeded
 *  - status.success(): The action has failed
 *
 * The statuses emitted by these functions are clones of
 * the object they came from, so they are safe to emit to callbacks.
 */

import DisplayableError from './DisplayableError';

class ActionStatus {
  constructor(statusMsg, error, isRunning, succeeded, step) {
    this.statusMsg = statusMsg || '';
    this.error = error || null;
    this.isRunning = isRunning || true;
    this.succeeded = succeeded || false;
    this.step = step || 0;
  }

  /* called when a user action moves on to the next step */
  nextStep = (msg) => {
    this.step++;
    this.statusMsg = msg || '';
    return { ...this };
  };

  /* called when a user action succeeds */
  success = (msg) => {
    this.step++;
    this.statusMsg = msg || '';
    this.isRunning = false;
    this.succeeded = true;
    return { ...this };
  };

  /* called when a user action fails with an error */
  fail = (msg, displayErr) => {
    /* don't increment the step on error */
    this.statusMsg = msg || '';
    this.error = displayErr || new DisplayableError(new Error('unknown error'));
    this.isRunning = false;
    this.succeeded = false;
    return { ...this };
  };
}

export default ActionStatus;

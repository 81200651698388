/* Generic QR code scanner dialog */

import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DialogStatusText from '../basic/DialogStatusText';
import makeStyles from '@mui/styles/makeStyles';
import QrReader from 'react-qr-scanner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomBar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingLeft: '24px',
  },
  statusText: {
    paddingTop: '8px',
    paddingBottom: '8px',
    color: theme.palette.text.primaryOnBackground,
  },
  statusTextError: {
    paddingTop: '8px',
    paddingBottom: '8px',
    color: theme.palette.text.errorOnBackground,
  },
}));

function QRCodeDialog(props) {
  const styles = useStyles();
  const [error, setError] = useState(null);
  const [scannedValue, setScannedValue] = useState(null);

  /* called every frame the scanner runs */
  function onScan(rawData) {
    /*
     * Do nothing if we have data (no QR code on screen) or
     * we have already found a QR code that the caller has
     * accepted as valid.
     */
    if (!rawData || !rawData.text || scannedValue !== null) return;

    /*
     * The caller can register a callback to decide whether
     * this QR code is valid. If it exists it should return
     * a non-null value with the parsed text. This parsed
     * text will be displayed to the user instead of the
     * raw text.
     */
    if (props.onScanFrame) {
      const parsedData = props.onScanFrame(rawData.text);
      if (parsedData === null) return;

      /* drop any existing errors when we find a value */
      setError(null);
      setScannedValue(parsedData);
    } else {
      /* drop any existing errors when we find a value */
      setError(null);
      setScannedValue(rawData.text);
    }
  }

  /* called when the low-level QR scanner experiences an error */
  function onError(err) {
    setError(err);
  }

  /* called when the user dismisses the dialog */
  function onCloseButton() {
    setError(null);
    setScannedValue(null);
    props.onClose();
  }

  /* called when the user clicks the rescan button */
  function onRescanButton() {
    setScannedValue(null);
  }

  /* called when the user accepts the found QR code */
  function onSubmitButton() {
    /*
     * Check for null because the hotkey will work
     * even if the submit button is disabled.
     */
    if (scannedValue === null) return;

    setError(null);
    setScannedValue(null);
    props.onSubmit(scannedValue);
  }

  /* hotkey handler */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  /* get status text to be displayed */
  function getStatusText() {
    if (error) return 'Failed to open camera (is it in use?)';
    else if (scannedValue) return scannedValue;
    else return '';
  }

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <div className={styles.root}>
          <QrReader delay={100} onError={onError} onScan={onScan} />
        </div>
      </DialogContent>
      <div className={styles.bottomBar}>
        <DialogStatusText className={styles.statusText} isError={!!error}>
          {getStatusText()}
        </DialogStatusText>
        <DialogActions>
          <Button onClick={onCloseButton}>Cancel</Button>
          <Button disabled={!scannedValue} onClick={onRescanButton}>
            Rescan
          </Button>
          <Button disabled={!scannedValue} onClick={onSubmitButton}>
            Submit
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default QRCodeDialog;

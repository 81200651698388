/* Component for displaying seat Status (as reported by the get_status) command */

import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHsStatus } from '../../hsHooks';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: '16px',
  },
}));

function Status(props) {
  const styles = useStyles();
  const hsStatus = useHsStatus();

  /* helper for getting the display name for a status variable */
  function nameString(name) {
    if (name === 'utcTimestamp') return 'UTC Timestamp';
    else if (name === 'timeOfLastCheckin') return 'Last Checkin (UTC)';
    else if (name === 'timeOfLastRecording') return 'Last Recording (UTC)';

    return name.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }

  /* returns the display string for a status variable value */
  function valueString(name, value) {
    if (name === 'utcTimestamp' || name === 'timeOfLastRecording' || name === 'timeOfLastCheckin') {
      if (value === 0) return 'Never';
      else
        return moment(new Date(value * 1000))
          .utc()
          .format('YYYY/MM/DD - HH:mm:ss');
    } else if (name === 'runtime') {
      return moment.duration(value).humanize();
    } else if (name === 'bootloaderVersion') {
      const major = (value & 0xff000000) >> 24;
      const minor = (value & 0x00ff0000) >> 16;
      const build = (value & 0x0000ffff) >> 0;
      return `${major}.${minor}.${build}`;
    }

    if (typeof value === 'number' && value % 1 !== 0) return value.toFixed(2).toString();
    else return value.toString();
  }

  return (
    <div className={styles.root}>
      <Typography variant='h4' align='left'>
        Status
      </Typography>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell width='50%' align='left'>
              Name
            </TableCell>
            <TableCell align='left'>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hsStatus
            ? Object.entries(hsStatus).map((kv) => (
                <TableRow key={kv[0]}>
                  <TableCell>{nameString(kv[0])}</TableCell>
                  <TableCell>{valueString(kv[0], kv[1])}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </div>
  );
}

export default Status;

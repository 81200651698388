/* View for logged in users with EolUserLevel.ADMIN level access */

/*
 * This user can do everything that EolUserLevel.USER can do, but they
 * can additionally configure how the EoL test should run.
 */

import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Log from '../persistent/Log';
import TestRunner from '../persistent/TestRunner';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.custom.paperSpacing,
  },
  testRunnerContainer: {
    display: 'flex',
  },
  logContainer: {
    display: 'flex',
  },
}));

function AdminView(props) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.testRunnerContainer}>
        <Paper style={{ flex: 1 }}>
          <TestRunner showConnect={true} showConfig={true} />
        </Paper>
      </div>
      <div className={styles.logContainer}>
        <Paper style={{ flex: 1 }}>
          <Log />
        </Paper>
      </div>
    </div>
  );
}

export default AdminView;

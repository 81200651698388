/* Helper component representing an editable number in a table */

import { TableCell, TextField } from '@mui/material';

function EditableNumericTableCell(props) {
  return (
    <TableCell>
      <TextField
        variant='outlined'
        size='small'
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        onChange={props.onChange}
        value={props.value}
      />
    </TableCell>
  );
}

export default EditableNumericTableCell;

/* Dialog for updating the seat operating config */

import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, Button } from '@mui/material';
import DialogStatusText from '../basic/DialogStatusText';
import makeStyles from '@mui/styles/makeStyles';
import hsm from '../../HsManager';
import eolm from '../../EolManager';
import GenericObjectUpdater from '../basic/GenericObjectUpdater';
import HsDefaultOpConfig from '../../lib/HsDefaultOpConfig';

const useStyles = makeStyles((theme) => ({
  configContainer: {
    display: 'flex',
  },
  rootConfigTable: {
    flex: '1',
    margin: '8px',
  },
  recConfigTable: {
    flex: '1',
    margin: '8px',
  },
  bottomBar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingLeft: '24px',
  },
  statusText: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

function OpConfigDialog(props) {
  const styles = useStyles();
  const [opConfig, setOpConfig] = useState({ ...HsDefaultOpConfig, ...props.opConfig });
  const [statusMsg, setStatusMsg] = useState('');
  const [statusMsgIsError, setStatusMsgIsError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  /* called by GenericObjectUpdater to update the root config */
  function onRootConfigChanged(newConfig) {
    setOpConfig({ ...opConfig, ...newConfig });
  }

  /* called by GenericObjectUpdater to update the recording config */
  function onRecConfigChanged(newRecConfig) {
    setOpConfig({ ...opConfig, rec_config: { ...newRecConfig } });
  }

  /* called when the submit button is clicked */
  function onSubmitButton() {
    props.onSubmit(opConfig);
  }

  /* called when the 'reset defaults' button is clicked */
  function onResetDefaultsButton() {
    props.onSubmit({ ...HsDefaultOpConfig });
  }

  /* called when the dialog is dismissed */
  function onCloseButton() {
    props.onClose();
    setStatusMsg('');
    setStatusMsgIsError(false);
    setSubmitButtonDisabled(false);
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  /* whenever the actual config changes we should use that instead */
  useEffect(() => {
    setOpConfig({ ...HsDefaultOpConfig, ...props.opConfig });
  }, [props.opConfig]);

  useEffect(() => {
    /* maintain the status of the upload process and the current op config */
    function onUploadOpConfigStatus(uploadOpConfigStatus) {
      setStatusMsg(uploadOpConfigStatus.statusMsg);
      setStatusMsgIsError(!!uploadOpConfigStatus.error);
      setSubmitButtonDisabled(uploadOpConfigStatus.isRunning);
    }

    function onHsOpConfig(hsOpConfig) {
      setOpConfig({ ...HsDefaultOpConfig, hsOpConfig });
    }

    eolm.registerEventHandler('uploadOpConfigStatus', onUploadOpConfigStatus);
    hsm.registerEventHandler('hsOpConfig', onHsOpConfig);

    return () => {
      eolm.unregisterEventHandler('uploadOpConfigStatus', onUploadOpConfigStatus);
      hsm.unregisterEventHandler('hsOpConfig', onHsOpConfig);
    };
  }, []);

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton} maxWidth='lg' fullWidth>
      <DialogTitle>Operating Configuration</DialogTitle>
      <DialogContent>
        <div className={styles.configContainer}>
          <div className={styles.rootConfigTable}>
            <DialogContentText>Root Configuration</DialogContentText>
            <GenericObjectUpdater object={opConfig} setObject={onRootConfigChanged} />
          </div>
          <div className={styles.recConfigTable}>
            <DialogContentText>Recording Configuration</DialogContentText>
            <GenericObjectUpdater object={opConfig['rec_config']} setObject={onRecConfigChanged} />
          </div>
        </div>
      </DialogContent>
      <div className={styles.bottomBar}>
        <DialogStatusText className={styles.statusText} isError={statusMsgIsError}>
          {statusMsg}
        </DialogStatusText>
        <DialogActions>
          <Button onClick={onCloseButton}>Close</Button>
          <Button disabled={submitButtonDisabled} onClick={onResetDefaultsButton}>
            Reset Defaults
          </Button>
          <Button disabled={submitButtonDisabled} onClick={onSubmitButton}>
            Submit
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default OpConfigDialog;

import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import EditableBooleanTableCell from './EditableBooleanTableCell';
import EditableNumericTableCell from './EditableNumericTableCell';
import EditableStringTableCell from './EditableStringTableCell';

function GenericObjectUpdater(props) {
  /* changed when a string is changed */
  function onStringValueChanged(name, newVal) {
    const newObject = { ...props.object };
    newObject[name] = newVal;
    props.setObject(newObject);
  }

  /* changed when a number is changed */
  function onNumericValueChanged(name, newVal) {
    const newObject = { ...props.object };
    newObject[name] = parseInt(newVal) || 0;
    props.setObject(newObject);
  }

  /* changed when a bool is changed */
  function onBoolValueChanged(name, newVal) {
    const newObject = { ...props.object };
    newObject[name] = newVal;
    props.setObject(newObject);
  }

  /* returns the display name for an object variable */
  function nameString(name) {
    return name
      .replaceAll('_', ' ')
      .replace(/^./g, function (str) {
        return str.toUpperCase();
      })
      .replace(/\s./g, function (str) {
        return str.toUpperCase();
      });
  }

  /* returns a TableRow that allows the user to edit props.object */
  function getTableRowComponent(key) {
    if (typeof props.object[key] === 'string') {
      return (
        <TableRow key={key}>
          <TableCell>{nameString(key)}</TableCell>
          <EditableStringTableCell onChange={(event) => onStringValueChanged(key, event.target.value)} value={props.object[key]} />
        </TableRow>
      );
    } else if (typeof props.object[key] === 'number') {
      return (
        <TableRow key={key}>
          <TableCell>{nameString(key)}</TableCell>
          <EditableNumericTableCell onChange={(event) => onNumericValueChanged(key, event.target.value)} value={props.object[key]} />
        </TableRow>
      );
    } else if (typeof props.object[key] === 'boolean') {
      return (
        <TableRow key={key}>
          <TableCell>{nameString(key)}</TableCell>
          <EditableBooleanTableCell onChange={(event) => onBoolValueChanged(key, event.target.checked)} value={props.object[key]} />
        </TableRow>
      );
    }

    /* don't allow updating nested types */
    return null;
  }

  return (
    <Table size='small' className={props.className}>
      <TableHead>
        <TableRow>
          <TableCell width='50%' align='left'>
            Name
          </TableCell>
          <TableCell align='left'>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{Object.keys(props.object).map((key) => getTableRowComponent(key))}</TableBody>
    </Table>
  );
}

export default GenericObjectUpdater;

/* Generic Dialog for displaying text */

import React from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import DialogStatusText from '../basic/DialogStatusText';

function InfoDialog(props) {
  /* Called when the dialog is dismissed. Open state is maintained by the parent */
  function onCloseButton() {
    props.onClose();
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onCloseButton();
    }
  }

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton}>
      <DialogContent>
        <DialogStatusText>{props.message}</DialogStatusText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseButton}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoDialog;

/* Flow for prompting the user for test configs */

import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useEolUserActionRunning } from '../../hsHooks';
import TestConfigDialog from '../dialogs/TestConfigDialog';

function TestConfigFlow(props) {
  const userActionRunning = useEolUserActionRunning();
  const [dialogOpen, setDialogOpen] = useState(false);
  const disabled = props.disabled || userActionRunning;

  /* called when the dialog is submitted */
  function onConfigSubmit(config) {
    setDialogOpen(false);
    props.onSubmit(config);
  }

  return (
    <>
      <Button className={props.className} disabled={disabled} variant='contained' color='primary' onClick={() => setDialogOpen(true)}>
        Configure Test
      </Button>
      <TestConfigDialog
        open={dialogOpen}
        currentTestConfig={props.currentTestConfig}
        onClose={() => setDialogOpen(false)}
        onSubmit={onConfigSubmit}
      />
    </>
  );
}

export default TestConfigFlow;

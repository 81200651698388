import { Alert, AlertTitle } from '@mui/material';
import { useFirmwareVersion } from '../../hsHooks';
import semver from 'semver';

function VersionWarning(props) {
  const fwVersion = useFirmwareVersion();

  if (fwVersion && semver.lt(fwVersion, '0.12.0')) {
    return (
      <Alert variant='filled' severity='warning'>
        <AlertTitle>{'Version Incompatibility Detected (< v0.12.0)'}</AlertTitle>
        Some functionality may not work. Please upgrade to v0.18.0.
      </Alert>
    );
  } else if (fwVersion && semver.lt(fwVersion, '0.18.0')) {
    return (
      <Alert variant='filled' severity='info'>
        <AlertTitle>{'Force Upload Disabled (< v0.18.0)'}</AlertTitle>
        Force Upload is not available in this firmware. Please update to use this feature.
      </Alert>
    );
  } else {
    /* if we don't know the firmware version or is new enough display nothing */
    return null;
  }
}

export default VersionWarning;

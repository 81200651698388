/* Generic flow for basic button click -> action operations */

import { Button } from '@mui/material';
import { useBleConnStatus, useHsUserActionRunning } from '../../hsHooks';
import HsConnStatus from '../../lib/HsConnStatus';

function BasicFlow(props) {
  /*
   * The button is automatically disabled when other BLE
   * actions are running and when BLE is disconnected.
   */
  const bleConnStatus = useBleConnStatus();
  const userActionRunning = useHsUserActionRunning();
  const disabled = props.disabled || userActionRunning || bleConnStatus !== HsConnStatus.CONNECTED;

  return (
    <Button className={props.className} disabled={disabled} variant='contained' color='primary' onClick={props.onClick}>
      {props.label}
    </Button>
  );
}

export default BasicFlow;

/* Dialog for beginning recording */

/*
 * This dialog has 2 modes:
 *  - props.showCurrents = false: the user can only start normal recordings
 *  - props.showCurrents = true: the user may also select PPG currents for the next recording
 */

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button, MenuItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  recConfigContainer: {
    display: 'flex',
    marginTop: '16px',
  },
  currentConfigContainer: {
    display: 'flex',
    marginTop: '16px',
  },
  textInput: {
    flex: '1',
    minWidth: '160px',
    marginTop: theme.custom.textFieldSpacing,
  },
}));

function RecordingDialog(props) {
  const styles = useStyles();
  const [recConfig, setRecConfig] = useState({ reason: '1', duration: '30', irCurrent: '0', redCurrent: '0' });

  /* called when the recording reason is changed */
  function onRecordingReasonChanged(newReason) {
    const newConfig = { ...recConfig };
    newConfig.reason = newReason;
    setRecConfig(newConfig);
  }

  /* called when the recording duration is changed */
  function onRecordingDurationChanged(newDuration) {
    const newConfig = { ...recConfig };
    newConfig.duration = newDuration;
    setRecConfig(newConfig);
  }

  /* called when the PPG red current is changed */
  function onRecordingRedCurrentChanged(newCurrent) {
    const newConfig = { ...recConfig };
    newConfig.redCurrent = newCurrent;
    setRecConfig(newConfig);
  }

  /* called when the PPG IR current is changed */
  function onRecordingIrCurrentChanged(newCurrent) {
    const newConfig = { ...recConfig };
    newConfig.irCurrent = newCurrent;
    setRecConfig(newConfig);
  }

  /*
   * Called when the user asks to set the next recording currents.
   * Only available if props.showCurrents is true.
   */
  function onSubmitRecordingCurrentsButton() {
    const redCurrent = parseInt(recConfig.redCurrent) || 0;
    const irCurrent = parseInt(recConfig.irCurrent) || 0;

    props.onSubmitCurrents(redCurrent, irCurrent);
  }

  /* Called when the user requests to start the recording */
  function onSubmitButton() {
    const parsedRecConfig = {
      reason: parseInt(recConfig.reason) || 0,
      duration: parseInt(recConfig.duration) || 0,
      redCurrent: parseInt(recConfig.redCurrent) || 0,
      irCurrent: parseInt(recConfig.irCurrent) || 0,
    };

    /* dialog open state is maintained by the parent */
    props.onSubmit(parsedRecConfig);
  }

  /* called when the user dismisses the dialog */
  function onCloseButton() {
    props.onClose();
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton}>
      <DialogTitle>Create a Recording</DialogTitle>
      <DialogContent>
        <DialogContentText>Select recording configuration</DialogContentText>
        <div className={styles.recConfigContainer}>
          <TextField
            className={styles.textInput}
            select
            variant='outlined'
            size='small'
            label='Reason'
            onChange={(event) => onRecordingReasonChanged(event.target.value)}
            value={recConfig.reason}
          >
            <MenuItem value='1'>Test</MenuItem>
            <MenuItem value='2'>Calibration</MenuItem>
            <MenuItem value='3'>Manufacturing</MenuItem>
            <MenuItem value='4'>Developer</MenuItem>
          </TextField>
          <TextField
            className={styles.textInput}
            variant='outlined'
            size='small'
            label='Duration'
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={(event) => onRecordingDurationChanged(event.target.value)}
            value={recConfig.duration}
          />
        </div>
        {props.showCurrents ? (
          <div className={styles.currentConfigContainer}>
            <TextField
              className={styles.textInput}
              variant='outlined'
              size='small'
              label='Red Current (mA)'
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event) => onRecordingRedCurrentChanged(event.target.value)}
              value={recConfig.redCurrent}
            />
            <TextField
              className={styles.textInput}
              variant='outlined'
              size='small'
              label='IR current (mA)'
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onChange={(event) => onRecordingIrCurrentChanged(event.target.value)}
              value={recConfig.irCurrent}
            />
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseButton}>Cancel</Button>
        {props.showCurrents ? <Button onClick={onSubmitRecordingCurrentsButton}>Set Currents Only</Button> : null}
        <Button onClick={onSubmitButton}>Start</Button>
      </DialogActions>
    </Dialog>
  );
}

export default RecordingDialog;

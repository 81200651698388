/* Component for displaying generic error messages */

import { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, AlertTitle, Collapse } from '@mui/material';
import DisplayableError from '../../DisplayableError';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
  },
}));

/* helper for providing a minimum standard set of fields errors must have */
function standardizeError(err) {
  /* all errors that get displayed here should already be DisplayableErrors */
  if (err instanceof DisplayableError) return err;

  /* fallback handling for non-standard errors */
  return new DisplayableError('Unexpected error', err);
}

function ErrorAlert(props) {
  const styles = useStyles();
  const [errorExpanded, setErrorExpanded] = useState(false);

  /* don't display if there is no error */
  if (!props.error) return null;

  const err = standardizeError(props.error);

  return (
    <Alert
      className={styles.root}
      onDoubleClick={() => setErrorExpanded((oldVal) => !oldVal)}
      onClose={props.onErrorClose}
      variant='filled'
      severity='error'
    >
      <AlertTitle>{err.message}</AlertTitle>
      <Collapse in={props.detailsVisible && errorExpanded} timeout='auto' unmountOnExit>
        <pre>{err.lowerError.stack}</pre>
      </Collapse>
    </Alert>
  );
}

export default ErrorAlert;

/* Flow for refreshing the seat status and config */

import hsm from '../../HsManager';
import BasicFlow from './BasicFlow';

function RefreshStateFlow(props) {
  return <BasicFlow className={props.className} disabled={props.disabled} label='Refresh State' onClick={hsm.refreshStateUI} />;
}

export default RefreshStateFlow;

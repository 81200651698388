/* Component for displaying info about the logged in user and the logout button */

import { Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '4px',
    gap: theme.custom.paperSpacing,
  },
}));

function UserInfo(props) {
  const styles = useStyles();

  /* get display string for this user's access level */
  function getAccessLevelText(level) {
    return level.charAt(0).toUpperCase() + level.slice(1);
  }

  /* if the user isn't logged in we display nothing */
  if (window.APP_CONFIG.isBle || !props.userToken) return null;

  return (
    <div className={styles.root}>
      <Typography align='left'>{`User: ${props.userToken.user}`}</Typography>
      <Typography align='left'>{`Access: ${getAccessLevelText(props.userToken.level)}`}</Typography>
      <Button variant='contained' color='primary' onClick={props.onLogout}>
        Logout
      </Button>
    </div>
  );
}

export default UserInfo;

/* Custom hooks for business logic updates */

import { useEffect, useState } from 'react';
import hsm from './HsManager';
import eolm from './EolManager';
import HsConnStatus from './lib/HsConnStatus';
import HsDefaultUserConfig from './lib/HsDefaultUserConfig';

/* hook for keeping track of the BLE connection status */
function useBleConnStatus() {
  const [bleConnStatus, setBleConnStatus] = useState(HsConnStatus.DISCONNECTED);

  useEffect(() => {
    function onBleConnectionEvent(newConnStatus) {
      setBleConnStatus(newConnStatus);
    }

    hsm.registerEventHandler('bleConnStatus', onBleConnectionEvent);
    return () => {
      hsm.unregisterEventHandler('bleConnStatus', onBleConnectionEvent);
    };
  }, []);

  return bleConnStatus;
}

/* hook for keeping track of the most current firmware version */
function useFirmwareVersion() {
  const [fwVersion, setFwVersion] = useState(null);

  useEffect(() => {
    function onHsStatus(newHsStatus) {
      setFwVersion(newHsStatus.firmwareVersion);
    }

    hsm.registerEventHandler('hsStatus', onHsStatus);
    return () => {
      hsm.unregisterEventHandler('hsStatus', onHsStatus);
    };
  }, []);

  return fwVersion;
}

/* hook for keeping track of the most up-to-date seat status (see 'get_status' command) */
function useHsStatus() {
  const [hsStatus, setHsStatus] = useState(null);

  useEffect(() => {
    function onHsStatus(newHsStatus) {
      setHsStatus(newHsStatus);
    }

    hsm.registerEventHandler('hsStatus', onHsStatus);
    return () => {
      hsm.unregisterEventHandler('hsStatus', onHsStatus);
    };
  }, []);

  return hsStatus;
}

/* hook for keeping track of the most up-to-date seat user config (see 'get_config' command) */
function useUserConfig() {
  const [userConfig, setUserConfig] = useState({ ...HsDefaultUserConfig });

  useEffect(() => {
    function onUserConfig(newUserConfig) {
      setUserConfig(newUserConfig);
    }

    hsm.registerEventHandler('hsUserConfig', onUserConfig);
    return () => {
      hsm.unregisterEventHandler('hsUserConfig', onUserConfig);
    };
  }, []);

  return userConfig;
}

/* hook for keeping track of the most up-to-date seat op config (see 'get_config' command) */
function useOpConfig() {
  const [opConfig, setOpConfig] = useState(null);

  useEffect(() => {
    function onOpConfig(newOpConfig) {
      setOpConfig(newOpConfig);
    }

    hsm.registerEventHandler('hsOpConfig', onOpConfig);
    return () => {
      hsm.unregisterEventHandler('hsOpConfig', onOpConfig);
    };
  }, []);

  return opConfig;
}

/* hook for keeping tack of userAction events emitted by the HsManager */
function useHsUserActionRunning() {
  const [running, setRunning] = useState(false);

  useEffect(() => {
    function onUserAction(actionStatus) {
      setRunning(actionStatus.running);
    }

    hsm.registerEventHandler('userAction', onUserAction);
    return () => {
      hsm.unregisterEventHandler('userAction', onUserAction);
    };
  }, []);

  return running;
}

/* hook for keeping tack of userAction events emitted by the EolManager */
function useEolUserActionRunning() {
  const [running, setRunning] = useState(false);

  useEffect(() => {
    function onUserAction(actionStatus) {
      setRunning(actionStatus.running);
    }

    eolm.registerEventHandler('userAction', onUserAction);
    return () => {
      eolm.unregisterEventHandler('userAction', onUserAction);
    };
  }, []);

  return running;
}

export { useBleConnStatus, useFirmwareVersion, useHsStatus, useHsUserActionRunning, useUserConfig, useOpConfig, useEolUserActionRunning };

import ParamDef from './ParamDef';

/* type representing a string parameter / return data */
class StringDef extends ParamDef {
  async unpack(buf) {
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(buf).split('\x00')[0];
  }

  async pack(unpackedArg) {
    const encoder = new TextEncoder('utf-8');
    return Buffer.from([...encoder.encode(unpackedArg), '\0']);
  }

  print(unpackedArg) {
    return unpackedArg.toString();
  }
}

export default StringDef;

/* Flow for connecting to a seat over BLE */

/*
 * This flow supports 3 modes of operation
 *  - props.connMethod = 'noFilter': connect to any seat without a filter
 *  - props.connMethod = 'prefix': connect to a seat with a given serial prefix
 *  - props.connMethod = 'scan': connect via scanning a QR code
 *
 * If no connMethod is specified a small dropdown button will be visible next to
 * the main button which allows the user to choose which of these methods they
 * would like to use.
 *
 * The button displays the status of the connection.
 */

import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingButton } from '@mui/lab';
import { ButtonGroup, Button, Grow, Popper, Paper, MenuList, MenuItem, ClickAwayListener, TextField } from '@mui/material';
import { Bluetooth, ArrowDropDown } from '@mui/icons-material';
import { useBleConnStatus } from '../../hsHooks';
import HsConnStatus from '../../lib/HsConnStatus';
import hsm from '../../HsManager';
import eolm from '../../EolManager';
import QRCodeDialog from '../dialogs/QRCodeDialog';
import BleAuthDialog from '../dialogs/BleAuthDialog';

const useStyles = makeStyles((theme) => ({
  textInput: {
    width: '150px',
    '& fieldset': {
      /*
       * This is so the textinput doesn't inherit border-radius from the theme
       * We don't want this because this is actually the middle of the button group.
       */
      borderRadius: '0px',
    },
    '& input': {
      /*
       * This is just thin enough to fit on phones without causing side to side scrolling,
       * but just wide enough to fit all 12 characters of the serial.
       */
      paddingLeft: '11px',
      paddingRight: '11px',
    },
  },
}));

/* list of available methods for connecting over BLE */
const BleConnectMethod = {
  NOFILTER: 'noFilter',
  PREFIX: 'prefix',
  SCAN: 'scan',
};

function BleConnectFlow(props) {
  const styles = useStyles();
  const bleConnStatus = useBleConnStatus();
  const [anchorEl, setAnchorEl] = useState(null); /* needed for the popper */
  const [connMethod, setConnMethod] = useState(getInitialConnMethod());
  const [connPrefixFilter, setConnPrefixFilter] = useState('');
  const [selectingConnMethod, setSelectingConnMethod] = useState(false);
  const [scanDialogOpen, setScanDialogOpen] = useState(false);
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const buttonColor = bleConnStatus === HsConnStatus.DISCONNECTED ? 'secondary' : 'primary';
  const connTransitioning =
    bleConnStatus === HsConnStatus.CONNECTING ||
    bleConnStatus === HsConnStatus.DISCONNECTING ||
    bleConnStatus === HsConnStatus.CONNECTED_NEED_AUTH ||
    bleConnStatus === HsConnStatus.CONNECTED_NEED_LOAD;

  /* Get the user's saved connection method. Return NOFILTER if none has been set */
  function getInitialConnMethod() {
    if (props.connMethod) return props.connMethod;

    try {
      const savedPreference = localStorage.getItem('bleConnMethod');
      if (!savedPreference) throw new Error('no saved ble conn method preference');
      if (typeof savedPreference !== 'string' || Object.values(BleConnectMethod).indexOf(savedPreference) < 0)
        throw new Error('invalid ble conn method preference');

      return savedPreference;
    } catch (err) {
      /* default to NOFILTER if no value or an invalid value is found */
      localStorage.removeItem('bleConnMethod');
      return BleConnectMethod.NOFILTER;
    }
  }

  /* called with the raw data from a QR code every time the scanner finds one */
  function onScanFrame(data) {
    const words = data.split('\r\n');

    /* check if this is a valid QR code for a seat */
    if (words.length !== 3 || words[0].length !== 12) return null;

    /* return the parsed serial number */
    return words[0];
  }

  /* called with the parsed data from onScanFrame() once a valid one is found */
  function onScanComplete(macAddress) {
    hsm.bleConnectUI(macAddress);
    setScanDialogOpen(false);
  }

  /* called when the user cancels scanning */
  function onScanCancelled() {
    setScanDialogOpen(false);
    if (!window.APP_CONFIG.isBle) eolm.changePosition(0);
  }

  function onAuthSubmit(bleConfig) {
    hsm.bleAuthConnectUI(bleConfig);
  }

  function onAuthCancelled() {
    hsm.bleDisconnectUI();
    setAuthDialogOpen(false);
    if (process.env.REACT_APP_IS_EOL) eolm.changePosition(0);
  }

  /* called when the user wants to change the BLE connection method */
  function onConnMethodButton(event) {
    /* the anchor is used by the popper to decide where to render */
    setAnchorEl(event.currentTarget.parentElement);
    setSelectingConnMethod(!selectingConnMethod);
  }

  /* called when the user selects a new BLE connection method */
  function onConnMethodSelected(method) {
    setSelectingConnMethod(false);
    setConnMethod(method);
    localStorage.setItem('bleConnMethod', method);
  }

  /* called when the user changes the prefix filter */
  function onPrefixFilterChanged(newVal) {
    /* force the user to type something that could be a real serial number prefix */
    if (!/^[0-9A-Fa-f]{0,12}$/.test(newVal)) return;

    /* force the user to type in uppercase for consistency */
    setConnPrefixFilter(newVal.toUpperCase());
  }

  /* called when the button is clicked */
  function onConnectButton() {
    if (bleConnStatus === HsConnStatus.DISCONNECTED && connMethod === BleConnectMethod.SCAN) {
      /*
       * If we're currently disconnected and in scanning mode open the scanner.
       * Connecting is done upon successful QR code scan in onScanComplete().
       */
      setScanDialogOpen(true);
      if (!window.APP_CONFIG.isBle) eolm.changePosition(1);
    } else if (bleConnStatus === HsConnStatus.DISCONNECTED && connMethod === BleConnectMethod.PREFIX) {
      /* if we're currently disconnected and using a prefix filter, connect using the filter */
      hsm.bleConnectUI(connPrefixFilter);
    } else if (bleConnStatus === HsConnStatus.DISCONNECTED) {
      /* if we're currently disconnected and not filtering, initiate connection */
      hsm.bleConnectUI(null);
    } else if (bleConnStatus === HsConnStatus.CONNECTED) {
      /* if we're currently connected, disconnect */
      hsm.bleDisconnectUI();
    } else {
      /* we are currently connecting, do nothing */
    }
  }

  /* helper function to figure out the text to be displayed for the 'connect' button */
  function getConnectButtonText(connStatus) {
    switch (connStatus) {
      case HsConnStatus.DISCONNECTED:
        if (connMethod === BleConnectMethod.SCAN) return 'Scan QR Code';
        else return 'Connect';
      case HsConnStatus.CONNECTED:
        return 'Disconnect';
      case HsConnStatus.CONNECTING:
      case HsConnStatus.CONNECTED_NEED_AUTH:
      case HsConnStatus.CONNECTED_NEED_LOAD:
        return 'Connecting';
      case HsConnStatus.DISCONNECTING:
        return 'Disconnecting';
      default:
        /* this shouldn't happen */
        throw new Error('invalid BLE connection state');
    }
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onConnectButton();
    }
  }

  useEffect(() => {
    function onBleConnectionEvent(newConnStatus) {
      setAuthDialogOpen(newConnStatus === HsConnStatus.CONNECTED_NEED_AUTH);

      /*
       * We silently move on to this finalization step once we reach the
       * CONNECTED_NEED_LOAD state. The user didn't explicitly ask for
       * this so we use the *EmitErr() variant insead of the *UI() one.
       */
      if (newConnStatus === HsConnStatus.CONNECTED_NEED_LOAD) hsm.bleFinalizeConnectEmitErr();
    }

    hsm.registerEventHandler('bleConnStatus', onBleConnectionEvent);
    return () => {
      hsm.unregisterEventHandler('bleConnStatus', onBleConnectionEvent);
    };
  }, []);

  return (
    <>
      <ButtonGroup variant='contained' color={buttonColor} className={props.className}>
        <LoadingButton
          disabled={props.disabled}
          loading={connTransitioning}
          endIcon={<Bluetooth />}
          variant='contained'
          loadingPosition='end'
          color={buttonColor}
          onClick={onConnectButton}
        >
          {getConnectButtonText(bleConnStatus)}
        </LoadingButton>
        {connMethod === BleConnectMethod.PREFIX ? (
          <TextField
            className={styles.textInput}
            disabled={props.disabled || bleConnStatus !== HsConnStatus.DISCONNECTED}
            variant='outlined'
            size='small'
            label='Serial Prefix'
            onChange={(event) => onPrefixFilterChanged(event.target.value)}
            onKeyPress={handleKeyPress}
            value={connPrefixFilter}
          />
        ) : null}
        {!props.connMethod ? (
          <Button
            disabled={props.disabled || connTransitioning}
            variant='contained'
            size='small'
            color={buttonColor}
            onClick={onConnMethodButton}
          >
            <ArrowDropDown />
          </Button>
        ) : null}
      </ButtonGroup>
      <Popper open={selectingConnMethod} anchorEl={anchorEl} placement='bottom-start' transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setSelectingConnMethod(false)}>
                <MenuList>
                  <MenuItem
                    selected={connMethod === BleConnectMethod.NOFILTER}
                    onClick={() => onConnMethodSelected(BleConnectMethod.NOFILTER)}
                  >
                    No Filter
                  </MenuItem>
                  <MenuItem selected={connMethod === BleConnectMethod.PREFIX} onClick={() => onConnMethodSelected(BleConnectMethod.PREFIX)}>
                    Prefix Filter
                  </MenuItem>
                  <MenuItem selected={connMethod === BleConnectMethod.SCAN} onClick={() => onConnMethodSelected(BleConnectMethod.SCAN)}>
                    QR Code
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <QRCodeDialog
        open={scanDialogOpen}
        title='Scan to Connect'
        onScanFrame={onScanFrame}
        onSubmit={onScanComplete}
        onClose={onScanCancelled}
      />
      <BleAuthDialog open={authDialogOpen} onClose={onAuthCancelled} onSubmit={onAuthSubmit} />
    </>
  );
}

export default BleConnectFlow;

import { DialogContentText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.primaryOnBackground,
  },
  errorText: {
    color: theme.palette.text.errorOnBackground,
  },
}));

function DialogStatusText(props) {
  const styles = useStyles();
  const colorClass = props.isError ? styles.errorText : styles.text;

  return <DialogContentText className={`${props.className} ${colorClass}`}>{props.children}</DialogContentText>;
}

export default DialogStatusText;

/* Flow for rebooting the seat */

import hsm from '../../HsManager';
import BasicFlow from './BasicFlow';

function RebootFlow(props) {
  return <BasicFlow className={props.className} disabled={props.disabled} label='Reboot' onClick={hsm.rebootUI} />;
}

export default RebootFlow;

/* Generic parent class for business logic code */

import ActionStatus from './ActionStatus';
import DisplayableError from './DisplayableError';

class GenericManager {
  constructor() {
    this.eventCbs = {};
  }

  /* register an event handler with a given name */
  registerEventHandler(name, cb) {
    if (!this.eventCbs[name]) this.eventCbs[name] = [];

    this.eventCbs[name].push(cb);
  }

  /* unregister an event handler with a given name */
  unregisterEventHandler(name, searchCb) {
    if (!this.eventCbs[name]) return;

    this.eventCbs[name] = this.eventCbs[name].filter((cb) => cb !== searchCb);
  }

  /* emit an event to all registered handlers */
  emitEvent(name, params) {
    if (!this.eventCbs[name]) return;

    this.eventCbs[name].forEach((cb) => cb(params));
  }

  /*
   * This helper allows callers to easily emit userAction events for
   * any functions that are meant to be called externally. This is
   * especially useful for logging external requests and ensuring
   * that components can keep track of when a Manager is busy.
   */
  userActionWrapper(name, cb) {
    return async (...args) => {
      try {
        this.emitEvent('userAction', { name, args, running: true });
        await cb(...args);
      } finally {
        this.emitEvent('userAction', { name, args, running: false });
      }
    };
  }

  /* wrapper that emits a generic error event if an error occurs */
  genericErrorCatchWrapper(errorTitle, cb) {
    return async (...args) => {
      try {
        await cb(...args);
      } catch (err) {
        this.emitEvent('error', new DisplayableError(errorTitle, err));
      }
    };
  }

  /* wrapper that emits a task-specific error event if an error occurs */
  taskSpecificErrorCatchWrapper(eventName, errorTitle, cb) {
    return async (...args) => {
      try {
        await cb(...args);
      } catch (err) {
        /* errors with this process are handled by their initiator */
        const status = new ActionStatus(); /* step count is lost in this generic wrapper */
        const displayErr = new DisplayableError(errorTitle, err);
        this.emitEvent(eventName, status.fail(errorTitle, displayErr));
      }
    };
  }
}

export default GenericManager;

/* Component for controlling the physical EoL Tester apparatus */

import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEolUserActionRunning } from '../../hsHooks';
import eolm from '../../EolManager';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '16px',
  },
  eolImageContainer: {
    display: 'flex',
    padding: '4px',
  },
  eolImage: {
    pointerEvents: 'none',
    height: '500px',
    width: 'auto',
  },
  eolImageButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.custom.buttonSpacing,
  },
  padding1: {
    flexGrow: '1',
  },
  padding2: {
    flexGrow: '5',
  },
  padding3: {
    flexGrow: '20',
  },
  eolOtherActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  eolOtherActionsButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px',
  },
  freqTextInput: {
    marginLeft: theme.custom.buttonSpacing,
    maxWidth: '120px',
  },
}));

function EolControl(props) {
  const styles = useStyles();
  const userActionRunning = useEolUserActionRunning();
  const [eolPosition, setEolPosition] = useState(0);
  const [ecgTestFreq, setEcgTestFreq] = useState('20');
  const [ecgTestRunning, setEcgTestRunning] = useState(false);
  const [impTestRunning, setImpTestRunning] = useState(false);

  /* called when the user changes the ECG test frequency */
  function onEcgTestFreqChanged(newFreq) {
    setEcgTestFreq(newFreq);
  }

  /* called when the user clicks the ECG test button */
  function onTestEcgButton() {
    if (ecgTestRunning) {
      eolm.stopTestEcgUI();
    } else {
      const freq = parseInt(ecgTestFreq) || 0;
      eolm.startTestEcgUI(freq);
    }
  }

  /* called when the user clicks the IMP test button */
  function onTestImpButton() {
    if (impTestRunning) eolm.stopTestImpUI();
    else eolm.startTestImpUI();
  }

  /* called when the user clicks one of the EoL potitions */
  function onEolPositionClicked(positionId) {
    eolm.changePositionUI(positionId);
  }

  useEffect(() => {
    /* maintain the status of the ECG test, IMP test, and EoL postion */
    function onEolPosition(newPosition) {
      setEolPosition(newPosition);
    }

    function onTestEcgStatus(testEcgStatus) {
      setEcgTestRunning(testEcgStatus.running);
    }

    function onTestImpStatus(testImpStatus) {
      setImpTestRunning(testImpStatus.running);
    }

    eolm.registerEventHandler('eolPosition', onEolPosition);
    eolm.registerEventHandler('testEcgStatus', onTestEcgStatus);
    eolm.registerEventHandler('testImpStatus', onTestImpStatus);

    /*
     * We don't have a way to ask the EoL apparatus what position
     * it is currently in. In order to resolve this we would normally
     * call eolm.resetTesterUI() to set the EoL apparatus to a known
     * position. However, this component is always rendered on the
     * page with TestRunner which does the same thing and unfortunately
     * the EoL server does not work with multiple simultaneous requests.
     * Therefore, we rely on the TestRunner component to reset it for us
     * while this component simply waits for the new position.
     */

    return () => {
      eolm.unregisterEventHandler('eolPosition', onEolPosition);
      eolm.unregisterEventHandler('testEcgStatus', onTestEcgStatus);
      eolm.unregisterEventHandler('testImpStatus', onTestImpStatus);
    };
  }, []);

  return (
    <div className={styles.root}>
      <Typography variant='h4' align='left'>
        End of Line Tester
      </Typography>
      <div className={styles.eolImageContainer}>
        <img src={process.env.PUBLIC_URL + 'eol_tester.png'} className={styles.eolImage} alt='logo' />
        <div className={styles.eolImageButtonContainer}>
          <div className={styles.padding1} />
          <Button
            disabled={userActionRunning}
            variant='contained'
            color={eolPosition === 3 ? 'secondary' : 'primary'}
            onClick={() => onEolPositionClicked(3)}
          >
            3
          </Button>
          <Button
            disabled={userActionRunning}
            variant='contained'
            color={eolPosition === 2 ? 'secondary' : 'primary'}
            onClick={() => onEolPositionClicked(2)}
          >
            2
          </Button>
          <Button
            disabled={userActionRunning}
            variant='contained'
            color={eolPosition === 1 ? 'secondary' : 'primary'}
            onClick={() => onEolPositionClicked(1)}
          >
            1
          </Button>
          <div className={styles.padding2} />
          <Button
            disabled={userActionRunning}
            variant='contained'
            color={eolPosition === 0 ? 'secondary' : 'primary'}
            onClick={() => onEolPositionClicked(0)}
          >
            0
          </Button>
          <div className={styles.padding3} />
        </div>
      </div>
      <div className={styles.eolOtherActionsContainer}>
        <div className={styles.eolOtherActionsButtonWrapper}>
          <Button
            disabled={userActionRunning}
            variant='contained'
            color={ecgTestRunning ? 'secondary' : 'primary'}
            onClick={onTestEcgButton}
          >
            {ecgTestRunning ? 'Stop ECG Test' : 'Start ECG Test'}
          </Button>
          <TextField
            className={styles.freqTextInput}
            disabled={userActionRunning || ecgTestRunning}
            variant='outlined'
            size='small'
            label='Frequency (Hz)'
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={(event) => onEcgTestFreqChanged(event.target.value)}
            value={ecgTestFreq}
          />
        </div>
        <div className={styles.eolOtherActionsButtonWrapper}>
          <Button
            disabled={userActionRunning}
            variant='contained'
            color={impTestRunning ? 'secondary' : 'primary'}
            onClick={onTestImpButton}
          >
            {impTestRunning ? 'Stop IMP Test' : 'Start IMP Test'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EolControl;

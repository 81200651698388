/* Dialog for configuring wifi */

import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import DialogStatusText from '../basic/DialogStatusText';
import HsDefaultUserConfig from '../../lib/HsDefaultUserConfig';
import hsm from '../../HsManager';

const CLOUD_DOMAIN_SUFFIX = '.casanacare.com';

const useStyles = makeStyles((theme) => ({
  textInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.custom.textFieldSpacing,
    gap: theme.custom.textFieldSpacing,
  },
  textInput: {
    minWidth: '400px',
  },
  bottomBar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingLeft: '24px',
  },
  statusText: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  endpointContainer: {
    display: 'flex',
    marginTop: '8px',
  },
  endpointDomainText: {
    margin: '0px',
    padding: '8px',
  },
}));

function WifiConfigDialog(props) {
  const styles = useStyles();
  const [wifiConfig, setWifiConfig] = useState({ ...HsDefaultUserConfig, ...props.wifiConfig });
  const [statusMsg, setStatusMsg] = useState('');
  const [statusMsgIsError, setStatusMsgIsError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  /* called when the SSID is changed */
  function onSSIDChanged(newSSID) {
    const newConfig = { ...wifiConfig };
    newConfig['wifi_ssid'] = newSSID;
    setWifiConfig(newConfig);
  }

  /* called when the password is changed */
  function onPasswordChanged(newPassword) {
    const newConfig = { ...wifiConfig };
    newConfig['wifi_pass'] = newPassword;
    setWifiConfig(newConfig);
  }

  /* called when the cloud endpoint is changed */
  function onEndpointChanged(newEndpoint) {
    const newConfig = { ...wifiConfig };
    newConfig['cloud_endpoint'] = `${newEndpoint}${CLOUD_DOMAIN_SUFFIX}`;
    setWifiConfig(newConfig);
  }

  /* called when the submit button is clicked */
  function onSubmitButton() {
    props.onSubmit(wifiConfig);
  }

  /* called when the dialog is dismissed */
  function onCloseButton() {
    props.onClose();
    setStatusMsg('');
    setStatusMsgIsError(false);
    setSubmitButtonDisabled(false);
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  /* whenever the actual config changes we should use that instead */
  useEffect(() => {
    setWifiConfig({ ...HsDefaultUserConfig, ...props.wifiConfig });
  }, [props.wifiConfig]);

  useEffect(() => {
    /* maintain the status of the setup test and the current user config */
    function onSetupWifiStatus(setupWifiStatus) {
      setStatusMsg(setupWifiStatus.statusMsg);
      setStatusMsgIsError(!!setupWifiStatus.error);
      setSubmitButtonDisabled(setupWifiStatus.isRunning);
    }

    function onHsUserConfig(hsUserConfig) {
      setWifiConfig(hsUserConfig);
    }

    hsm.registerEventHandler('setupWifiStatus', onSetupWifiStatus);
    hsm.registerEventHandler('hsUserConfig', onHsUserConfig);

    return () => {
      hsm.unregisterEventHandler('setupWifiStatus', onSetupWifiStatus);
      hsm.unregisterEventHandler('hsUserConfig', onHsUserConfig);
    };
  }, []);

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton}>
      <DialogTitle>WiFi Configuration</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter your WiFi SSID and Password.</DialogContentText>
        <div className={styles.textInputContainer}>
          <TextField
            className={styles.textInput}
            variant='outlined'
            size='small'
            label='SSID'
            onChange={(event) => onSSIDChanged(event.target.value)}
            value={wifiConfig['wifi_ssid']}
          />
          <TextField
            className={styles.textInput}
            variant='outlined'
            size='small'
            label='Password'
            type='password'
            onChange={(event) => onPasswordChanged(event.target.value)}
            value={wifiConfig['wifi_pass']}
          />
          <div className={styles.endpointContainer}>
            <TextField
              variant='outlined'
              size='small'
              label='Endpoint'
              onChange={(event) => onEndpointChanged(event.target.value)}
              value={wifiConfig['cloud_endpoint'].replace(CLOUD_DOMAIN_SUFFIX, '')}
            />
            <DialogContentText className={styles.endpointDomainText}>{CLOUD_DOMAIN_SUFFIX}</DialogContentText>
          </div>
        </div>
      </DialogContent>
      <div className={styles.bottomBar}>
        <DialogStatusText className={styles.statusText} isError={statusMsgIsError}>
          {statusMsg}
        </DialogStatusText>
        <DialogActions>
          <Button onClick={onCloseButton}>Close</Button>
          <Button disabled={submitButtonDisabled} onClick={onSubmitButton}>
            Submit
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default WifiConfigDialog;

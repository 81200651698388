/* Generic Dialog for prompting the user to confirm an action */

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DialogStatusText from '../basic/DialogStatusText';

const useStyles = makeStyles((theme) => ({
  bottomBar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingLeft: '24px',
  },
  statusText: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

function ConfirmDialog(props) {
  const styles = useStyles();

  /* Called when the dialog is closed. State maintained by parent */
  function onCloseButton() {
    props.onClose();
  }

  /* Called when the user confirms their action */
  function onSubmitButton() {
    if (!props.disableSubmit) props.onSubmit();
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <div className={styles.bottomBar}>
        <DialogStatusText className={styles.statusText} isError={props.statusMsgIsError}>
          {props.statusMsg}
        </DialogStatusText>
        <DialogActions>
          <Button onClick={onCloseButton}>Cancel</Button>
          <Button disabled={props.disableSubmit} onClick={onSubmitButton}>
            {props.submitText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;

/* error wrapper for user-friendly error messages */

class DisplayableError {
  constructor(message, lowerError) {
    this.message = message;
    this.lowerError = lowerError;
  }
}

export default DisplayableError;

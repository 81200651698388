/* BLE-only access to the seat */

/*
 * This view is used for installing and debugging purposes.
 * It can run all available BLE commands and view the debug
 * log. This view does not use / depend on the EoL tester.
 */

import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Status from '../persistent/Status';
import Config from '../persistent/Config';
import Log from '../persistent/Log';
import BleConnectFlow from '../flows/BleConnectFlow';
import RefreshStateFlow from '../flows/RefreshStateFlow';
import WifiSetupFlow from '../flows/WifiSetupFlow';
import RecordingFlow from '../flows/RecordingFlow';
import ForceCheckinFlow from '../flows/ForceCheckinFlow';
import DisableWifiFlow from '../flows/DisableWifiFlow';
import RebootFlow from '../flows/RebootFlow';
import FactoryResetFlow from '../flows/FactoryResetFlow';
import ForceUploadFlow from '../flows/ForceUploadFlow';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.custom.paperSpacing,
  },
  actionBarContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.custom.buttonSpacing,
  },
  statusConfigContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.custom.paperSpacing,
  },
  statusContainer: {
    display: 'flex',
    flex: '1',
    minWidth: '320px',
  },
  configContainer: {
    display: 'flex',
    flex: '1',
    minWidth: '320px',
  },
  resetButtonBarContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    padding: '16px',
    boxSizing: 'border-box',
    gap: theme.custom.buttonSpacing,
  },
  logContainer: {
    display: 'flex',
  },
}));

function MainView(props) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.actionBarContainer}>
        <BleConnectFlow />
        <RefreshStateFlow />
        <RecordingFlow />
        <ForceCheckinFlow />
        <ForceUploadFlow />
        <RebootFlow />
      </div>
      <div className={styles.statusConfigContainer}>
        <div className={styles.statusContainer}>
          <Paper style={{ flex: 1 }}>
            <Status />
          </Paper>
        </div>
        <div className={styles.configContainer}>
          <Paper style={{ flex: 1 }}>
            <Config />
            <div className={styles.resetButtonBarContainer}>
              <WifiSetupFlow />
              <DisableWifiFlow />
              <FactoryResetFlow />
            </div>
          </Paper>
        </div>
      </div>
      <div className={styles.logContainer}>
        <Paper style={{ flex: 1 }}>
          <Log />
        </Paper>
      </div>
    </div>
  );
}

export default MainView;

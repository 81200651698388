/* Business Logic for the End of Line Tester */

/*
 * Note that this manager sits "above" the HsManager class conceptually.
 * In addition to maintaining basic logic for working with the physical
 * EoL tester, it also contains the logic for running the EoL test itself
 * which involves making commands to the seat, and requests to the cloud.
 *
 * Externally, this class provides 3 different ways to call
 * each action (here called xxx):
 *
 *  - xxx(): These variants simply perform the requested action.
 *      Any errors encountered are thrown as-is.
 *
 *  - xxxEmitErr(): These variants wrap the requested action in an error
 *      handler so that errors are emitted instead of being thrown.
 *
 *  - xxxUI(): These variants emit errors instead of throwing and
 *      additionally emit 'userAction' events before and after they
 *      are called. This is useful for allowing components to provide
 *      feedback to the user when they are pressed.
 */

import DisplayableError from './DisplayableError';
import ActionStatus from './ActionStatus';
import { FW_VERSION_BLE_AUTH } from './lib/hsParamTypes/HsDefs';
import hsi from './lib/HeartSeatInterface';
import GenericManager from './GenericManager';
import hsm from './HsManager';
import EolUserLevel from './EolUserLevel';
import semver from 'semver';

class EolManager extends GenericManager {
  constructor(eolConfig) {
    super();
    this.eolConfig = eolConfig;
    this.testRunning = false;
    this.cancellableSleepPromise = null;

    const tokenStr = localStorage.getItem('userToken');
    this.userToken = tokenStr ? JSON.parse(tokenStr) : null;
  }

  /* parse a raw JSON user token from the EoL login service */
  parseUserToken(rawToken) {
    if (!rawToken || !rawToken['token'] || !rawToken['level']) throw new Error('invalid user token');

    if (Object.values(EolUserLevel).indexOf(rawToken['level']) < 0) throw new Error('invalid user level');

    return { ...rawToken };
  }

  /* helper to parse the response text from a fetch request and return json */
  parseJsonRes(resText) {
    if (resText === '') return null;

    try {
      return JSON.parse(resText);
    } catch (err) {
      /* create a more useful error if we end up with non-json data */
      throw new Error(`failed to parse fetch response: '${resText}'`);
    }
  }

  /* helper for making authenticated calls to the EoL API */
  async eolApiRequest(route, userHeaders) {
    let headers = { ...userHeaders };
    if (this.userToken) headers['X-Token'] = this.userToken.authToken;

    const res = await fetch(`${this.eolConfig.eolBaseUrl}${route}`, { headers });
    const resText = await res.text();

    /* check for bad http response code */
    if (!res.ok) throw new Error(`failed to perform EoL request (${res.status}): ${resText}`);

    return this.parseJsonRes(resText);
  }

  /* helper for making authenticated calls to the casana cloud */
  async cloudApiRequest(endpoint, method, route, body) {
    const baseUrl = `${endpoint}${this.eolConfig.cloudApiBase}`;

    return await fetch(`https://${baseUrl}${route}`, {
      method,
      headers: {
        'X-Casana-User-Agent': this.eolConfig.cloudUserAgent,
        'X-Casana-Token': this.eolConfig.cloudToken,
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  /* get the cloud seat id for a given serial */
  async cloudFindSeatId(endpoint, serial) {
    const res = await this.cloudApiRequest(endpoint, 'GET', `/seat/${serial}`);
    const resText = await res.text();
    if (!res.ok) throw new Error(`failed to get cloud seat id (${res.status}): ${resText}`);

    const resJson = this.parseJsonRes(resText);
    return resJson['seat_id'];
  }

  /* Register a seat with the casana cloud. Returns a cloud seat id */
  async cloudRegisterSeat(endpoint, serial, fwVersion, bleAuthToken, publicKey) {
    let res;

    /* attempt to find an existing seat */
    res = await this.cloudApiRequest(endpoint, 'GET', `/seat/${serial}`);
    if (!res.ok) {
      /* Existing seat was not found. Create a new one */
      res = await this.cloudApiRequest(endpoint, 'POST', '/seat', {
        serial_number: serial,
        hardware_version: 'r3',
        firmware_version: fwVersion,
        target_firmware: null,
        ble_auth_token: bleAuthToken || undefined,
        public_key: publicKey || undefined,
      });
    }
    const resText = await res.text();

    /* check for bad http response code */
    if (!res.ok) throw new Error(`failed to register seat (${res.status}): ${resText}`);

    /*
     * Parse the response text. Both requests should return a JSON object
     * with the seat details, including a 'seat_id' number.
     */
    const resJson = this.parseJsonRes(resText);
    const seatID = resJson['seat_id'];

    /*
     * Check if the cloud's seat details include a public key and
     * BLE auth token. If either is missing we upload them (both are
     * required by the API endpoint).
     */
    if ((bleAuthToken && !resJson['ble_auth_token']) || (publicKey && !resJson['public_key'])) {
      const authRes = await this.cloudApiRequest(endpoint, 'PUT', `/seat/${seatID}/setauth`, {
        ble_auth_token: bleAuthToken,
        public_key: publicKey,
      });

      const authResText = await authRes.text();
      if (!authRes.ok) throw new Error(`failed to update seat auth variables (${authRes.status}): ${authResText}`);
    }

    return seatID;
  }

  /* upload an operating config for a given seat */
  async cloudUploadOpConfig(endpoint, seatCloudId, opConfig) {
    const res = await this.cloudApiRequest(endpoint, 'PUT', `/seat/${seatCloudId}/setconfig`, opConfig);
    const resText = await res.text();

    /* check for bad http response code */
    if (!res.ok) throw new Error(`failed to upload seat op config (${res.status}): ${resText}`);

    return this.parseJsonRes(resText);
  }

  /*
   * Sleep for a given timeout during the EoL test. This can be
   * cancelled by calling abortTest().
   */
  async doTestSleep(ms) {
    let timeout;
    let resolve;

    const sleepPromise = new Promise((res) => {
      timeout = setTimeout(res, ms);
      resolve = res;
    });

    sleepPromise.cancel = () => {
      clearTimeout(timeout);
      resolve();
    };

    /*
     * Only one EoL test may be running at a time and the EoL test
     * is the only place this function is called. In addition, this
     * function is the only place this.cancellableSleepPromise is
     * set. Therefore, we can be sure that this variable won't get
     * overwritten by someone else. this.abortTest() will simply
     * cancel the promise, but will not update the value.
     */
    this.cancellableSleepPromise = sleepPromise;
    await sleepPromise;
    this.cancellableSleepPromise = null;
  }

  /* convenience function used to for running steps of the actual EoL test */
  async runTestStep(status, statusMsg, cb, ...args) {
    if (!this.testRunning) throw new Error('test aborted');

    this.emitEvent('testStatus', status.nextStep(statusMsg));
    if (cb) return await cb(...args);
  }

  /**************** External Action Handlers ****************/

  /* gets the current user session token maintained by login / logout */
  getUserToken = () => {
    if (!this.userToken) return null;

    return {
      user: this.userToken.user,
      level: this.userToken.level,
    };
  };

  /* login to the EoL with a user / password */
  logIn = async (user, pass) => {
    const rawToken = await this.eolApiRequest('/login', { 'X-Auth-User': user, 'X-Auth-Pass': pass });
    const tokenObj = this.parseUserToken(rawToken);
    this.userToken = { user, authToken: tokenObj['token'], level: tokenObj['level'] };
    localStorage.setItem('userToken', JSON.stringify(this.userToken));
    this.emitEvent('userToken', this.getUserToken());
  };

  /* log out of the EoL API */
  logOut = async () => {
    await hsm.bleDisconnect();
    await this.eolApiRequest('/logout');
    this.userToken = null;
    localStorage.removeItem('userToken');
    this.emitEvent('userToken', this.getUserToken());
  };

  /* move the physical EoL tester to a new position (0 - 3) */
  changePosition = async (positionId) => {
    await this.eolApiRequest(`/move/${positionId}`);
    this.emitEvent('eolPosition', positionId);
  };

  /* start ECG test pulses on the physical EoL tester */
  startTestEcg = async (freq) => {
    await this.eolApiRequest(`/ecg/${freq}/1`);
    this.emitEvent('testEcgStatus', { running: true });
  };

  /* stop ECG test pulses on the physical EoL tester */
  stopTestEcg = async () => {
    await this.eolApiRequest(`/ecg/20/-1`);
    this.emitEvent('testEcgStatus', { running: false });
  };

  /* start IMP test pulses on the physical EoL tester */
  startTestImp = async () => {
    await this.eolApiRequest('/imp/1');
    this.emitEvent('testImpStatus', { running: true });
  };

  /* stop IMP test pulses on the physical EoL tester */
  stopTestImp = async () => {
    await this.eolApiRequest('/imp/-1');
    this.emitEvent('testImpStatus', { running: false });
  };

  /* reset the seat to a given position with ECG and IMP tests off */
  resetTester = async (position) => {
    await this.changePosition(position);
    await this.stopTestEcg();
    await this.stopTestImp();
  };

  /* upload an operating config */
  uploadOpConfig = async (serial, opConfig) => {
    const status = new ActionStatus();
    const endpoint = hsm.hsUserConfig['cloud_endpoint'];

    /* fetch the seat cloud id */
    this.emitEvent('uploadOpConfigStatus', status.nextStep('Fetching seat cloud id...'));
    const seatCloudId = await this.cloudFindSeatId(endpoint, serial);

    /* upload the new config to the cloud */
    this.emitEvent('uploadOpConfigStatus', status.nextStep('Uploading new operating config...'));
    await this.cloudUploadOpConfig(endpoint, seatCloudId, opConfig);

    /* force a checkin to download the new config to the seat */
    this.emitEvent('uploadOpConfigStatus', status.nextStep('Forcing checkin...'));
    await hsm.forceCheckin();

    /* refresh the app state so that its properly reflected in the UI */
    this.emitEvent('uploadOpConfigStatus', status.nextStep('Refreshing the op config...'));
    await hsm.refreshState();

    this.emitEvent('uploadOpConfigStatus', status.success());
  };

  /*
   * Run the actual EoL test from beginning to end. The config parameter
   * is optional and is allowed to include only fields that should be
   * different from the default. We don't use any of the GenericManager
   * error wrappers for this function because we want more control over
   * what errors are presented to the UI if something goes wrong.
   */
  runTestEmitErr = async (config) => {
    const status = new ActionStatus();

    const testConfig = { ...this.eolConfig.testConfigs, ...config };
    const testOpConfig = { ...this.eolConfig.opConfigs.test };
    const testEndpoint = this.eolConfig.userConfig['cloud_endpoint'];

    let i = 0;
    this.testRunning = true;
    try {
      /* we will normally be at position 1 already from scanning the QR code */
      await this.runTestStep(status, 'Resetting EoL tester', () => this.resetTester(1));

      const hsStatus = await this.runTestStep(status, 'Fetching seat status', () => hsi.handleCmd('get_status', null));
      const serial = hsStatus['serialNumber'];
      const fwVersion = hsStatus['firmwareVersion'];

      await this.runTestStep(status, `Connected to seat: serial = ${serial}, firmware = ${fwVersion}`, () => {
        /* we just want to print info to the log here */
      });

      let bleAuthToken, publicKey;
      if (semver.gte(fwVersion, FW_VERSION_BLE_AUTH)) {
        bleAuthToken = await this.runTestStep(status, 'Fetching BLE auth token', () => hsi.handleCmd('get_ble_auth_token', null));
        publicKey = await this.runTestStep(status, 'Fetching public key', () => hsi.handleCmd('get_public_key', null));
      } else {
        bleAuthToken = null;
        publicKey = null;
      }

      const seatCloudId = await this.runTestStep(status, 'Creating seat entry in the cloud', () =>
        this.cloudRegisterSeat(testEndpoint, serial, fwVersion, bleAuthToken, publicKey)
      );

      testOpConfig['automatic_recording'] = false;
      testOpConfig['automatic_upload'] = false;
      await this.runTestStep(status, 'Uploading test operating config', () =>
        this.cloudUploadOpConfig(testEndpoint, seatCloudId, testOpConfig)
      );

      await this.runTestStep(status, 'Setting user config', () => hsi.handleCmd('set_user_config', this.eolConfig.userConfig));

      await this.runTestStep(status, 'Forcing checkin to confirm user config works', () => hsm.forceCheckin());

      await this.runTestStep(status, 'Beginning recording 1', () =>
        hsm.beginRecording({
          reason: 1,
          duration: testConfig.initialTestDurationMs / 1000,
          redCurrent: testConfig.ppgCurrentsMa[i].red,
          irCurrent: testConfig.ppgCurrentsMa[i].ir,
        })
      );
      i++;

      await this.runTestStep(status, `Delaying ${testConfig.genericDelayMs + 1000}ms`, () =>
        this.doTestSleep(testConfig.genericDelayMs + 1000)
      );

      await this.runTestStep(status, 'Moving to position 2', () => this.changePosition(2));

      await this.runTestStep(status, 'Starting impedance', () => this.startTestImp());

      await this.runTestStep(status, `Delaying ${testConfig.impTestDurationMs}ms`, () => this.doTestSleep(testConfig.impTestDurationMs));

      await this.runTestStep(status, 'Stopping impedance', () => this.stopTestImp());

      await this.runTestStep(status, 'Moving to position 3', () => this.changePosition(3));

      await this.runTestStep(status, 'Starting ECG', () => this.startTestEcg(20));

      await this.runTestStep(status, `Delaying ${testConfig.ecgTestDurationMs}ms`, () => this.doTestSleep(testConfig.ecgTestDurationMs));

      await this.runTestStep(status, 'Stopping ECG', () => this.stopTestEcg());

      await this.runTestStep(status, `Waiting for recording to complete`, () =>
        this.doTestSleep(testConfig.genericDelayMs + testConfig.checkinDelayMs)
      );

      for (; i < testConfig.ppgCurrentsMa.length - 1; i++) {
        const recConfig = {
          reason: 1,
          duration: testConfig.ppgTestDurationMs / 1000,
          redCurrent: testConfig.ppgCurrentsMa[i].red,
          irCurrent: testConfig.ppgCurrentsMa[i].ir,
        };

        await this.runTestStep(status, `Beginning ppg recording ${i}`, () => hsm.beginRecording(recConfig));

        await this.runTestStep(status, `Waiting for recording to complete`, () =>
          this.doTestSleep(testConfig.ppgTestDurationMs + testConfig.checkinDelayMs)
        );
      }

      await this.runTestStep(status, 'Starting impedance', () => this.startTestImp());

      await this.runTestStep(status, 'Moving to position 1', () => this.changePosition(1));

      await this.runTestStep(status, `Delaying ${testConfig.genericDelayMs}ms`, () => this.doTestSleep(testConfig.genericDelayMs));

      testOpConfig['automatic_recording'] = true;
      testOpConfig['rec_config']['extra_seconds_at_end'] = 5;
      await this.runTestStep(status, 'Enabling automatic recordings', () =>
        this.cloudUploadOpConfig(testEndpoint, seatCloudId, testOpConfig)
      );

      await this.runTestStep(status, 'Setting PPG currents', () =>
        hsm.setRecordingCurrents(testConfig.ppgCurrentsMa[i].red, testConfig.ppgCurrentsMa[i].ir)
      );
      i++;

      await this.runTestStep(status, 'Forcing checkin', () => hsm.forceCheckin());

      await this.runTestStep(status, 'Delaying 3000ms', () => this.doTestSleep(3000));

      await this.runTestStep(status, 'Moving to position 2', () => this.changePosition(2));

      await this.runTestStep(status, `Delaying ${testConfig.weightTestDelayMs}ms`, () => this.doTestSleep(testConfig.weightTestDelayMs));

      await this.runTestStep(status, 'Moving to position 3', () => this.changePosition(3));

      await this.runTestStep(status, `Delaying ${testConfig.weightTestDelayMs}ms`, () => this.doTestSleep(testConfig.weightTestDelayMs));

      await this.runTestStep(status, 'Stopping impedance', () => this.stopTestImp());

      testOpConfig['automatic_recording'] = false;
      testOpConfig['automatic_upload'] = true;
      await this.runTestStep(status, 'Disabling automatic recordings', () =>
        this.cloudUploadOpConfig(testEndpoint, seatCloudId, testOpConfig)
      );

      await this.runTestStep(status, 'Moving to position 1', () => this.changePosition(1));

      await this.runTestStep(status, `Delaying ${testConfig.genericDelayMs}ms`, () => this.doTestSleep(testConfig.genericDelayMs));

      await this.runTestStep(status, 'Forcing checkin', () => hsm.forceCheckin());

      await this.runTestStep(status, 'Setting production operating config', () =>
        this.cloudUploadOpConfig(testEndpoint, seatCloudId, { ...this.eolConfig.opConfigs.prod })
      );

      await this.runTestStep(status, 'Forcing checkin', () => hsm.forceCheckin());

      this.emitEvent('testStatus', status.success('Test succeeded'));
    } catch (err) {
      const statusMsg = `EOL test failed: ${err.message}`;
      const displayErr = new DisplayableError('EoL test failed', err);
      this.emitEvent('testStatus', status.fail(statusMsg, displayErr));
    } finally {
      await this.resetTester(0);
      this.testRunning = false;
    }
  };

  /* stop a currently running EoL test (safe even if test isn't running) */
  abortTest = async () => {
    if (this.cancellableSleepPromise) this.cancellableSleepPromise.cancel();
    this.testRunning = false;
  };

  /* wrapped calls for emitting error events */
  logInEmitErr = this.genericErrorCatchWrapper('Login failed', this.logIn);
  logOutEmitErr = this.genericErrorCatchWrapper('Logout failed', this.logOut);
  changePositionEmitErr = this.genericErrorCatchWrapper('Failed to change EoL position', this.changePosition);
  startTestEcgEmitErr = this.genericErrorCatchWrapper('Failed to start ECG test', this.startTestEcg);
  stopTestEcgEmitErr = this.genericErrorCatchWrapper('Failed to stop ECG test', this.stopTestEcg);
  startTestImpEmitErr = this.genericErrorCatchWrapper('Failed to start IMP test', this.startTestImp);
  stopTestImpEmitErr = this.genericErrorCatchWrapper('Failed to stop IMP test', this.stopTestImp);
  resetTesterEmitErr = this.genericErrorCatchWrapper('Failed to reset tester', this.resetTester);
  uploadOpConfigEmitErr = this.taskSpecificErrorCatchWrapper(
    'uploadOpConfigStatus',
    'Failed to upload operating config',
    this.uploadOpConfig
  );

  /* wrapped calls for emitting userAction events that won't throw errors */
  changePositionUI = this.userActionWrapper('change position', this.changePositionEmitErr);
  startTestEcgUI = this.userActionWrapper('start test ECG', this.startTestEcgEmitErr);
  stopTestEcgUI = this.userActionWrapper('stop test ECG', this.stopTestEcgEmitErr);
  startTestImpUI = this.userActionWrapper('start test IMP', this.startTestImpEmitErr);
  stopTestImpUI = this.userActionWrapper('stop test IMP', this.stopTestImpEmitErr);
  resetTesterUI = this.userActionWrapper('reset tester', this.resetTesterEmitErr);
  uploadOpConfigUI = this.userActionWrapper('upload operating config', this.uploadOpConfigEmitErr);
  runTestUI = this.userActionWrapper('run test', this.runTestEmitErr);
}

/* Singleton implementation */
const eolm = new EolManager(window.APP_CONFIG.eol);
export default eolm;

/* Flow for starting and stopping recordings and setting PPG currents */

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import hsm from '../../HsManager';
import { useBleConnStatus, useHsUserActionRunning, useOpConfig } from '../../hsHooks';
import HsConnStatus from '../../lib/HsConnStatus';
import RecordingDialog from '../dialogs/RecordingDialog';

function RecordingFlow(props) {
  const bleConnStatus = useBleConnStatus();
  const opConfig = useOpConfig();
  const userActionRunning = useHsUserActionRunning();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState({ active: false, stopping: false, waitSecs: 0, durationSecs: 0 });
  const disabled = props.disabled || userActionRunning || !opConfig || bleConnStatus !== HsConnStatus.CONNECTED;

  useEffect(() => {
    /* maintain the status of the recording */
    function onRecStatus(recStatus) {
      setRecordingStatus(recStatus);
      if (recStatus.active) setDialogOpen(false);
    }

    hsm.registerEventHandler('recStatus', onRecStatus);
    return () => {
      hsm.unregisterEventHandler('recStatus', onRecStatus);
    };
  }, []);

  /* called when the button is clicked */
  function onRecordButton() {
    if (recordingStatus.active) {
      hsm.stopRecordingUI();
    } else {
      setDialogOpen(true);
    }
  }

  /* called when the user asks to set the next recording currents. */
  function onSubmitCurrents(redCurrent, irCurrent) {
    setDialogOpen(false);
    hsm.setRecordingCurrentsUI(redCurrent, irCurrent);
  }

  /* called when the recording dialog is submitted */
  function onRecordingSubmit(recConfig) {
    setDialogOpen(false);
    hsm.beginRecordingUI(recConfig);
  }

  /* helper for getting the display label of the recording button */
  function getRecordingButtonLabel(recStatus) {
    if (recStatus.active && recStatus.stopping) return 'Rec: Stopping...';
    else if (recStatus.active && recStatus.waitSecs !== 0) return 'Rec: Starting...';
    else if (recStatus.active) return `Rec: ${recStatus.durationSecs} (stop)`;
    else return 'Begin Recording';
  }

  return (
    <>
      <Button
        className={props.className}
        disabled={disabled}
        variant='contained'
        color={recordingStatus.active ? 'secondary' : 'primary'}
        onClick={onRecordButton}
      >
        {getRecordingButtonLabel(recordingStatus)}
      </Button>
      <RecordingDialog
        open={bleConnStatus === HsConnStatus.CONNECTED && dialogOpen}
        showCurrents={props.showCurrents}
        onClose={() => setDialogOpen(false)}
        onSubmitCurrents={onSubmitCurrents}
        onSubmit={onRecordingSubmit}
      />
    </>
  );
}

export default RecordingFlow;

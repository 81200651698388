/* Flow for disabling wifi */

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import hsm from '../../HsManager';
import { useBleConnStatus, useHsUserActionRunning } from '../../hsHooks';
import HsConnStatus from '../../lib/HsConnStatus';
import ConfirmDialog from '../dialogs/ConfirmDialog';

const DISABLE_WIFI_MSG = 'This will delete your saved wifi configuration. Cloud checkin will be disabled until it is reconfigured.';

function DisableWifiFlow(props) {
  const bleConnStatus = useBleConnStatus();
  const userActionRunning = useHsUserActionRunning();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [statusMsg, setStatusMsg] = useState('');
  const [statusMsgIsError, setStatusMsgIsError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const disabled = props.disabled || userActionRunning || bleConnStatus !== HsConnStatus.CONNECTED;

  useEffect(() => {
    function onDisableWifiStatus(disableWifiStatus) {
      /* close on success */
      if (disableWifiStatus.succeeded) {
        onCloseButton();
      } else {
        /* maintain the status of this action based on emitted events */
        setStatusMsg(disableWifiStatus.statusMsg);
        setStatusMsgIsError(!!disableWifiStatus.error);
        setSubmitButtonDisabled(disableWifiStatus.isRunning);
      }
    }

    hsm.registerEventHandler('disableWifiStatus', onDisableWifiStatus);
    return () => {
      hsm.unregisterEventHandler('disableWifiStatus', onDisableWifiStatus);
    };
  }, []);

  /* called when the user closes the dialog */
  function onCloseButton() {
    setStatusMsg('');
    setStatusMsgIsError(false);
    setSubmitButtonDisabled(false);
    setDialogOpen(false);
  }

  return (
    <>
      <Button className={props.className} disabled={disabled} variant='contained' color='primary' onClick={() => setDialogOpen(true)}>
        Disable Wifi
      </Button>
      <ConfirmDialog
        open={bleConnStatus === HsConnStatus.CONNECTED && dialogOpen}
        title='Disable Wifi'
        message={DISABLE_WIFI_MSG}
        submitText='Disable'
        statusMsg={statusMsg}
        statusMsgIsError={statusMsgIsError}
        disableSubmit={submitButtonDisabled}
        onClose={onCloseButton}
        onSubmit={hsm.disableWifiUI}
      />
    </>
  );
}

export default DisableWifiFlow;

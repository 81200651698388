/* View for logged in users with EolUserLevel.ENGINEER level access */

/*
 * This user has full access to manually control the EoL tester and
 * perform all available seat commands over BLE.
 */

import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEolUserActionRunning } from '../../hsHooks';
import Status from '../persistent/Status';
import Config from '../persistent/Config';
import TestRunner from '../persistent/TestRunner';
import Log from '../persistent/Log';
import BleConnectFlow from '../flows/BleConnectFlow';
import RefreshStateFlow from '../flows/RefreshStateFlow';
import WifiSetupFlow from '../flows/WifiSetupFlow';
import RecordingFlow from '../flows/RecordingFlow';
import ForceCheckinFlow from '../flows/ForceCheckinFlow';
import DisableWifiFlow from '../flows/DisableWifiFlow';
import RebootFlow from '../flows/RebootFlow';
import FactoryResetFlow from '../flows/FactoryResetFlow';
import ForceUploadFlow from '../flows/ForceUploadFlow';
import EolControl from '../persistent/EolControl';
import OpConfigFlow from '../flows/OpConfigFlow';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.custom.paperSpacing,
  },
  actionBarContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.custom.buttonSpacing,
  },
  statusConfigContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.custom.paperSpacing,
  },
  statusContainer: {
    display: 'flex',
    flex: '1',
    minWidth: '320px',
  },
  configContainer: {
    display: 'flex',
    flex: '1',
    minWidth: '320px',
  },
  eolContainer: {
    display: 'flex',
    flex: '1',
    minWidth: '320px',
  },
  resetButtonBarContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    padding: '16px',
    boxSizing: 'border-box',
    gap: theme.custom.buttonSpacing,
  },
  testRunnerContainer: {
    display: 'flex',
  },
  logContainer: {
    display: 'flex',
  },
}));

function EngineerView(props) {
  const styles = useStyles();
  const userActionRunning = useEolUserActionRunning();

  return (
    <div className={styles.root}>
      <div className={styles.actionBarContainer}>
        <BleConnectFlow disabled={userActionRunning} />
        <RefreshStateFlow disabled={userActionRunning} />
        <RecordingFlow disabled={userActionRunning} showCurrents={true} />
        <ForceCheckinFlow disabled={userActionRunning} />
        <ForceUploadFlow disabled={userActionRunning} />
        <OpConfigFlow disabled={userActionRunning} />
        <RebootFlow disabled={userActionRunning} />
      </div>
      <div className={styles.statusConfigContainer}>
        <div className={styles.statusContainer}>
          <Paper style={{ flex: 1 }}>
            <Status />
          </Paper>
        </div>
        <div className={styles.configContainer}>
          <Paper style={{ flex: 1 }}>
            <Config />
            <div className={styles.resetButtonBarContainer}>
              <WifiSetupFlow disabled={userActionRunning} />
              <DisableWifiFlow disabled={userActionRunning} />
              <FactoryResetFlow disabled={userActionRunning} />
            </div>
          </Paper>
        </div>
        <div className={styles.eolContainer}>
          <Paper style={{ flex: 1 }}>
            <EolControl />
          </Paper>
        </div>
      </div>
      <div className={styles.testRunnerContainer}>
        <Paper style={{ flex: 1 }}>
          <TestRunner showConfig={true} />
        </Paper>
      </div>
      <div className={styles.logContainer}>
        <Paper style={{ flex: 1 }}>
          <Log />
        </Paper>
      </div>
    </div>
  );
}

export default EngineerView;

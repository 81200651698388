/* Business Logic for the Heart Seat */

/*
 * Externally, this class provides 3 different ways to call
 * each action (here called xxx):
 *
 *  - xxx(): These variants simply perform the requested action.
 *      Any errors encountered are thrown as-is.
 *
 *  - xxxEmitErr(): These variants wrap the requested action in an error
 *      handler so that errors are emitted instead of being thrown.
 *
 *  - xxxUI(): These variants emit errors instead of throwing and
 *      additionally emit 'userAction' events before and after they
 *      are called. This is useful for allowing components to provide
 *      feedback to the user when they are pressed.
 */

import HsDefaultUserConfig from './lib/HsDefaultUserConfig';
import { FW_VERSION_BLE_AUTH } from './lib/hsParamTypes/HsDefs';
import hsi from './lib/HeartSeatInterface';
import HsConnStatus from './lib/HsConnStatus';
import flatten from 'flat';
import GenericManager from './GenericManager';
import DisplayableError from './DisplayableError';
import ActionStatus from './ActionStatus';
import semver from 'semver';

class HsManager extends GenericManager {
  constructor() {
    super();
    this.hsStatus = {};
    this.hsUserConfig = { ...HsDefaultUserConfig };
    this.hsOpConfig = null;
    this.isPolling = false;
    this.isAuthed = false;
    this.disablePollingCount = 0;
    this.recStatus = {
      active: false,
      stopping: false,
      waitSecs: 0,
      durationSecs: 0,
    };

    /* register event handlers with the lower-level heart seat interface */
    this.onBleConnectionEvent = this.onBleConnectionEvent.bind(this);
    this.onMsgError = this.onMsgError.bind(this);
    this.onDebugMsg = this.onDebugMsg.bind(this);
    this.onProcessEvent = this.onProcessEvent.bind(this);
    this.onPollRecStatus = this.onPollRecStatus.bind(this);

    hsi.registerConnectionStatusHandler(this.onBleConnectionEvent);
    hsi.registerDebugHandler(this.onDebugMsg);
    hsi.registerProcessEventHandler(this.onProcessEvent);
    hsi.registerMsgErrorHandler(this.onMsgError);

    /* setup our polling interval for checking recording status */
    setInterval(this.onPollRecStatus, 1000);
  }

  /**************** Lower-Level Event Handlers ****************/

  onBleConnectionEvent(newConnStatus) {
    /*
     * We manually emit the CONNECTED status in bleConnect() so that
     * components dont think the BLE connection is ready to be used
     * until we are done reading the state information. Therefore, we
     * need to manually suppress the actual CONNECTED event here.
     */
    this.isAuthed = false;
    if (newConnStatus !== HsConnStatus.CONNECTED) this.emitEvent('bleConnStatus', newConnStatus);
  }

  onMsgError(err) {
    this.emitEvent('error', new DisplayableError('Message receive error', err));
  }

  onDebugMsg(msgObj) {
    this.emitEvent('hsLogMsg', msgObj);
  }

  onProcessEvent(procEvent) {
    this.emitEvent('procEvent', procEvent);
  }

  /*
   * This function is polled with setInterval(). It is responsible for
   * maintaining this.recStatus and for emitting events when it changes.
   * Polling may fail spuriously depending on the BLE connection, so this
   * function is simply best effort.
   */
  async onPollRecStatus() {
    /*
     * Don't poll if:
     *  - we aren't connected to a device
     *  - a command is running that is doing its own polling
     *  - we are already polling
     */
    if (hsi.getConnectionState() !== HsConnStatus.CONNECTED || !this.isAuthed || this.disablePollingCount !== 0 || this.isPolling) return;

    this.isPolling = true;

    try {
      const hsRecStatus = await hsi.handleCmd('get_recording_status', null);
      if (hsRecStatus['isRecording']) {
        /* we have an active recording */
        this.recStatus.active = true;
        this.recStatus.durationSecs = hsRecStatus['lengthSeconds'];
        this.recStatus.waitSecs = 0;
      } else if (this.recStatus.active && this.recStatus.waitSecs !== 0) {
        /* the user has requested a recording and we are waiting for it to start */
        this.recStatus.waitSecs--;
      } else {
        /* a recording isn't running and we are not waiting for one to start */
        this.recStatus.active = false;
        this.recStatus.stopping = false;
        this.recStatus.waitSecs = 0;
      }

      this.emitEvent('recStatus', { ...this.recStatus });
    } catch (err) {
      /* it's ok if this fails occasionally. we will check again in 1 second */
    } finally {
      this.isPolling = false;
    }
  }

  /**************** Action Handlers ****************/

  /* fetch the most up-to-date status from the seat */
  loadStatus = async () => {
    const status = await hsi.handleCmd('get_status', null);
    this.hsStatus = flatten(status, { safe: true });
    this.emitEvent('hsStatus', this.hsStatus);
  };

  /* fetch the most up-to-date user config from the seat */
  loadUserConfig = async () => {
    try {
      const configBuf = await hsi.getFileData('user_cfg');
      this.hsUserConfig = JSON.parse(configBuf.toString());
    } catch (err) {
      /* check for ERR_NOENT specifically. Otherwise just throw the original error. */
      if (err.hsErrorCode && err.hsErrorCode === 1014) {
        this.hsUserConfig = { ...HsDefaultUserConfig };
      } else {
        throw err;
      }
    }
    this.emitEvent('hsUserConfig', this.hsUserConfig);
  };

  /* fetch the most up-to-date op config from the seat */
  loadOpConfig = async () => {
    try {
      if (window.APP_CONFIG.isBle) {
        /*
         * The BLE-only release of the app doesn't do anything with the
         * op config itself. and it takes a significant amount of time to
         * download from the seat. Therefore, we can skip reading it and
         * instead just check if it exists.
         */
        await hsi.handleCmd('file_get_info', 'op_cfg');
        this.hsOpConfig = {};
      } else {
        const configBuf = await hsi.getFileData('op_cfg');
        this.hsOpConfig = JSON.parse(configBuf.toString());

        /* the config token is maintained by the cloud for cache invalidation purposes */
        delete this.hsOpConfig['config_token'];
      }
    } catch (err) {
      /* check for ERR_NOENT specifically. Otherwise just throw the original error. */
      if (err.hsErrorCode && err.hsErrorCode === 1014) {
        this.hsOpConfig = null;
      } else {
        throw err;
      }
    }
    this.emitEvent('hsOpConfig', this.hsOpConfig);
  };

  /* helper function for updating the seat user config and notifying components */
  setUserConfig = async (userConfig) => {
    await hsi.handleCmd('set_user_config', userConfig);
    this.hsUserConfig = userConfig;
    this.emitEvent('hsUserConfig', this.hsUserConfig);
  };

  /* ask the seat to check in with the cloud and wait for it to do so */
  forceCheckinWaitImpl = async (doUpload) => {
    this.disablePollingCount++;

    try {
      /* get the most up-to-date last checkin time */
      let newStatus = await hsi.handleCmd('get_status', null);
      const oldCheckin = newStatus.timeOfLastCheckin;

      /* tell the seat to checkin at the next available opportunity (async) */
      if (doUpload) await hsi.handleCmd('checkin_with_upload', null);
      else await hsi.handleCmd('checkin', null);

      /* poll the last checkin time to see if we have had a successfully checkin */
      for (let i = 0; i < 45; i++) {
        await new Promise((r) => setTimeout(r, 1000));

        /* it's ok if an iteration of this loop fails. We will just try again */
        try {
          newStatus = await hsi.handleCmd('get_status', null);
        } catch (err) {
          continue;
        }

        /* see if we have a new last checkin time */
        if (oldCheckin !== newStatus.timeOfLastCheckin) {
          /*
           * Update the last-known status while we have it.
           * We could do this every time we poll, but that
           * would probably create enough background UI
           * changes to distract the user.
           */
          this.hsStatus = flatten(newStatus, { safe: true });
          this.emitEvent('hsStatus', this.hsStatus);
          return;
        }
      }

      /* We timed out. Throw an error */
      throw new Error('checkin timed out');
    } finally {
      this.disablePollingCount--;
    }
  };

  /* refresh the maintained seat status and configs */
  refreshState = async () => {
    this.disablePollingCount++;

    try {
      await this.loadStatus();
      await this.loadUserConfig();
      await this.loadOpConfig();
    } finally {
      this.disablePollingCount--;
    }
  };

  /*
   * Connect to the seat over BLE
   *
   * This method is a bit different from other actions in this class.
   * We don't present a bleConnect() or bleConnectEmitErr() variant for
   * 2 reasons. First, Web Bluetooth requires user interaction to select
   * a bluetooth device, so the programmatic variants are less useful.
   * Second, we don't want to emit a userAction event if the user decides
   * to close the bluetooth popup without selecting a device so we can't
   * use the generic userActionWrapper().
   */
  bleConnectUI = async (macAddress) => {
    const bluetoothCtx = await hsi.getBleContextFromBrowser(macAddress);
    if (!bluetoothCtx) return;

    this.disablePollingCount++;

    try {
      this.emitEvent('userAction', { name: 'ble connect', args: [macAddress], running: true });

      await hsi.connect(bluetoothCtx);

      /* see comment in onBleConnectionEvent() */
      const fwVersion = await hsi.handleCmd('get_fw_version', null);
      if (semver.gte(fwVersion, FW_VERSION_BLE_AUTH)) {
        this.emitEvent('bleConnStatus', HsConnStatus.CONNECTED_NEED_AUTH);
      } else {
        this.isAuthed = true;
        this.emitEvent('bleConnStatus', HsConnStatus.CONNECTED_NEED_LOAD);
      }
    } catch (err) {
      /* if we fail to refreshState() disconnect so we're not in a strange half-ready state */
      this.bleDisconnect();
      this.emitEvent('error', new DisplayableError('Failed to connect to BLE', err));
    } finally {
      this.disablePollingCount--;
      this.emitEvent('userAction', { name: 'ble connect', args: [macAddress], running: false });
    }
  };

  /*
   * Authorize a BLE connection with a provided config. This should be called from
   * the CONNECTED_NEED_AUTH state.
   */
  bleAuthConnect = async (bleConfig) => {
    const status = new ActionStatus();

    this.emitEvent('bleAuthConnect', status.nextStep('Authorizing connection...'));
    await hsi.handleCmd('ble_auth', bleConfig['authToken']);

    this.isAuthed = true;
    this.emitEvent('bleAuthConnect', status.success());
    this.emitEvent('bleConnStatus', HsConnStatus.CONNECTED_NEED_LOAD);
  };

  /*
   * Finalize a BLE connection by loading all state information. This should
   * be called from the CONNECTED_NEED_LOAD state.
   */
  bleFinalizeConnect = async () => {
    await this.refreshState();
    this.emitEvent('bleConnStatus', HsConnStatus.CONNECTED);
  };

  /* disconnect from the seat over BLE */
  bleDisconnect = async () => {
    hsi.disconnect();
    /* connection events emitted by onBleConnectionEvent() */
  };

  /* apply a provided user config and see if the seat is able to use it to checkin */
  setupWifi = async (wifiConfig) => {
    const status = new ActionStatus();

    /* apply the new wifi connection settings */
    this.emitEvent('setupWifiStatus', status.nextStep('Applying configuration...'));
    await this.setUserConfig(wifiConfig);

    /* attempt a test checkin */
    this.emitEvent('setupWifiStatus', status.nextStep('Performing checkin...'));
    await this.forceCheckinWaitImpl(false);

    /* reload the status and config with the updated values */
    this.emitEvent('setupWifiStatus', status.nextStep('Fetching new configuration...'));
    await this.refreshState();

    this.emitEvent('setupWifiStatus', status.success());
  };

  /* disable wifi by setting a default user config with no wifi credentials */
  disableWifi = async () => {
    const status = new ActionStatus();

    /* set the user config to the default value to disable wifi */
    this.emitEvent('disableWifiStatus', status.nextStep('Disabling wifi...'));
    await this.setUserConfig({ ...HsDefaultUserConfig });

    /* update our status and configuration */
    this.emitEvent('disableWifiStatus', status.nextStep('Fetching new configuration...'));
    await this.refreshState();

    this.emitEvent('disableWifiStatus', status.success());
  };

  /* perform a factory reset */
  factoryReset = async () => {
    const status = new ActionStatus();

    this.disablePollingCount++;

    try {
      /* request the factory reset */
      this.emitEvent('factoryResetStatus', status.nextStep('Performing factory reset...'));
      await hsi.handleCmd('factory_reset', null);

      /* delay a few seconds while we wait for the reboot */
      this.emitEvent('factoryResetStatus', status.nextStep('Waiting for reboot...'));
      await new Promise((r) => setTimeout(r, 5000));

      /* update our status and configuration */
      this.emitEvent('factoryResetStatus', status.nextStep('Fetching new configuration...'));
      await this.refreshState();

      this.emitEvent('factoryResetStatus', status.success());
    } finally {
      this.disablePollingCount--;
    }
  };

  /* set PPG currents (in mA) for the next recording */
  setRecordingCurrents = async (redCurrent, irCurrent) => {
    const hsCurrents = {
      red_current_mA: redCurrent,
      ir_current_mA: irCurrent,
    };

    await hsi.handleCmd('set_next_led_currents', hsCurrents);
  };

  /* begin a forced recording with a provided config */
  beginRecording = async (recConfig) => {
    const hsRecConfig = {
      reason: recConfig.reason,
      duration: recConfig.duration,
    };

    /* if the user requested custom PPG currents apply those */
    if (recConfig.irCurrent || recConfig.redCurrent) {
      const hsCurrents = {
        ir_current_mA: recConfig.irCurrent,
        red_current_mA: recConfig.redCurrent,
      };
      await hsi.handleCmd('set_next_led_currents', hsCurrents);
    }

    /* update our maintained state to indicate we are waiting for a recording */
    this.recStatus.stopping = false;
    this.recStatus.active = true;
    this.recStatus.waitSecs = 5;
    await hsi.handleCmd('create_recording', hsRecConfig);

    /* recording status events emitted by onPollRecStatus() */
  };

  /* force stop a currently running recording */
  stopRecording = async () => {
    try {
      this.recStatus.stopping = true;
      await hsi.handleCmd('stop_recording', null);

      /* recording status events emitted by onPollRecStatus() */
    } catch (err) {
      /* if we weren't able to tell the seat to stop, undo the stopping state */
      this.recStatus.stopping = false;
      throw err;
    }
  };

  /* reboot the seat */
  reboot = async () => {
    const status = new ActionStatus();

    this.disablePollingCount++;

    try {
      /* request the reboot */
      this.emitEvent('rebootStatus', status.nextStep('Performing reboot...'));
      await hsi.handleCmd('software_reset', null);

      /* delay a few seconds while we wait for the reboot */
      status.statusMsg = 'Waiting for reboot...';
      this.emitEvent('rebootStatus', status.nextStep('Waiting for reboot...'));
      await new Promise((r) => setTimeout(r, 3000));

      this.emitEvent('rebootStatus', status.success());
    } finally {
      this.disablePollingCount--;
    }
  };

  /* check in with the cloud and wait for it to finish */
  forceCheckin = async () => {
    await this.forceCheckinWaitImpl(false);
  };

  /* check in with the cloud (forcing uploads) and wait for it to finish */
  forceUpload = async () => {
    await this.forceCheckinWaitImpl(true);
  };

  /* wrapped calls for emitting error events */
  refreshStateEmitErr = this.genericErrorCatchWrapper('Failed to refresh state', this.refreshState);
  bleAuthConnectEmitErr = this.taskSpecificErrorCatchWrapper('bleAuthConnect', 'BLE auth failed', this.bleAuthConnect);
  bleFinalizeConnectEmitErr = this.genericErrorCatchWrapper('Failed to finalize BLE connection', this.bleFinalizeConnect);
  bleDisconnectEmitErr = this.genericErrorCatchWrapper('Failed to disconnect from BLE', this.bleDisconnect);
  setupWifiEmitErr = this.taskSpecificErrorCatchWrapper('setupWifiStatus', 'Failed to setup wifi', this.setupWifi);
  disableWifiEmitErr = this.taskSpecificErrorCatchWrapper('disableWifiStatus', 'Failed to disable wifi', this.disableWifi);
  factoryResetEmitErr = this.taskSpecificErrorCatchWrapper('factoryResetStatus', 'Failed to factory reset', this.factoryReset);
  setRecordingCurrentsEmitErr = this.genericErrorCatchWrapper('Failed to set PPG currents', this.setRecordingCurrents);
  beginRecordingEmitErr = this.genericErrorCatchWrapper('Failed to begin recording', this.beginRecording);
  stopRecordingEmitErr = this.genericErrorCatchWrapper('Failed to stop recording', this.stopRecording);
  rebootEmitErr = this.taskSpecificErrorCatchWrapper('rebootStatus', 'Failed to reboot', this.reboot);
  forceCheckinEmitErr = this.genericErrorCatchWrapper('Failed to force checkin', this.forceCheckin);
  forceUploadEmitErr = this.genericErrorCatchWrapper('Failed to force upload', this.forceUpload);

  /* wrapped calls for emitting userAction events that won't throw errors */
  refreshStateUI = this.userActionWrapper('refresh state', this.refreshStateEmitErr);
  bleAuthConnectUI = this.userActionWrapper('ble auth', this.bleAuthConnectEmitErr);
  bleFinalizeConnectUI = this.userActionWrapper('ble finalize', this.bleFinalizeConnectEmitErr);
  bleDisconnectUI = this.userActionWrapper('ble disconnect', this.bleDisconnectEmitErr);
  setupWifiUI = this.userActionWrapper('setup wifi', this.setupWifiEmitErr);
  disableWifiUI = this.userActionWrapper('disable wifi', this.disableWifiEmitErr);
  factoryResetUI = this.userActionWrapper('factory reset', this.factoryResetEmitErr);
  setRecordingCurrentsUI = this.userActionWrapper('set ppg recording currents', this.setRecordingCurrentsEmitErr);
  beginRecordingUI = this.userActionWrapper('begin recording', this.beginRecordingEmitErr);
  stopRecordingUI = this.userActionWrapper('stop recording', this.stopRecordingEmitErr);
  rebootUI = this.userActionWrapper('reboot', this.rebootEmitErr);
  forceCheckinUI = this.userActionWrapper('force checkin', this.forceCheckinEmitErr);
  forceUploadUI = this.userActionWrapper('force upload', this.forceUploadEmitErr);
}

/* Singleton implementation */
const hsm = new HsManager();
export default hsm;

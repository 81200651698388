/* Definition of available themes for the app */

import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material';

const themeCommon = {
  palette: {
    primary: {
      main: '#6a6eff',
    },
    secondary: {
      main: '#f42c41',
    },
    error: {
      main: '#f42c41',
    },
    text: {
      logDebug: '#757575',
      logInfo: '#6a6eff',
      logWarn: '#c63EEB',
      logError: '#ed6c02',
      logPanic: '#f42c41',
      logSuccess: '#2e7d32',
    },
  },
  custom: {
    buttonSpacing: '8px',
    paperSpacing: '12px',
    textFieldSpacing: '12px',
  },
  shape: {
    borderRadius: '0px',
  },
  shadows: Array(25).fill('none') /* remove shadows */,
};

const darkTheme = createTheme(
  deepmerge(
    { ...themeCommon },
    {
      palette: {
        mode: 'dark',
        background: {
          paper: '#252836',
          log: '#1c1b20',
          default: '#1f1d2b',
        },
        text: {
          /* the primary text color isn't readable against a direct background in dark mode */
          primaryOnBackground: '#ffffff',
          secondaryOnBackground: themeCommon.palette.secondary.main,
          errorOnBackground: themeCommon.palette.error.main,
        },
      },
      img: {
        logoSvgName: 'casana-name-dark.svg',
      },
      components: {
        MuiButton: {
          styleOverrides: {
            text: {
              /* see above comment about primary color against dark backgrounds */
              color: '#ffffff',
            },
          },
        },
      },
    }
  )
);

const lightTheme = createTheme(
  deepmerge(
    { ...themeCommon },
    {
      palette: {
        mode: 'light',
        background: {
          paper: '#ffffff',
          log: '#efefef',
          default: '#f3f3f8',
        },
        text: {
          primaryOnBackground: themeCommon.palette.primary.main,
          secondaryOnBackground: themeCommon.palette.secondary.main,
          errorOnBackground: themeCommon.palette.error.main,
        },
      },
      img: {
        logoSvgName: 'casana-name-light.svg',
      },
    }
  )
);

export { themeCommon, lightTheme, darkTheme };

/* Flow for forcing checkin */

import hsm from '../../HsManager';
import { useUserConfig } from '../../hsHooks';
import BasicFlow from './BasicFlow';

function ForceCheckinFlow(props) {
  /* this button is disabled if no wifi SSID is configured */
  const userConfig = useUserConfig();

  return (
    <BasicFlow
      disabled={props.disabled || !userConfig.wifi_ssid || userConfig.wifi_ssid === ''}
      className={props.className}
      label='Force Checkin'
      onClick={() => hsm.forceCheckinUI()}
    />
  );
}
export default ForceCheckinFlow;

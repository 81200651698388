/* Helper component representing an editable boolean value in a table */

import { Switch, TableCell } from '@mui/material';

function EditableBooleanTableCell(props) {
  return (
    <TableCell>
      <Switch onChange={props.onChange} checked={props.value} />
    </TableCell>
  );
}

export default EditableBooleanTableCell;

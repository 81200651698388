const HsDefaultOpConfig = {
  automatic_recording: true,
  automatic_upload: true,
  debug_logging: true,
  max_awake_seconds: 3600,
  check_in_interval_seconds: 3600,
  max_cloud_retries: 1,
  check_in_retry_seconds: 3600,
  rec_config: {
    is_impedance_enabled: false,
    min_weight_lbs: 20,
    max_seconds: 600,
    min_seconds: 10,
    extra_seconds_at_end: 2,
    ppg_red_max_current_mA: 70,
    ppg_ir_max_current_mA: 18,
    ppg_red_resistance: 12,
    ppg_ir_resistance: 82.5,
    weight_bl_slope: 90,
    weight_br_slope: 90,
    weight_fl_slope: 90,
    weight_fr_slope: 90,
    weight_bl_offset: -20,
    weight_br_offset: -20,
    weight_fl_offset: -20,
    weight_fr_offset: -20,
  },
};

export default HsDefaultOpConfig;

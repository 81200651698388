import * as rs from 'restructure';
import * as concat from 'concat-stream';
import ParamDef from './ParamDef';

/* type representing a struct parameter / return data */
class StructDef extends ParamDef {
  constructor(schema) {
    super();
    this.schema = schema;
  }

  size() {
    return this.schema.size();
  }

  async unpack(buf) {
    let outDict = this.schema.decode(new rs.DecodeStream(buf));

    for (const key in outDict) {
      if (typeof outDict[key] === 'string') outDict[key] = outDict[key].substr(0, outDict[key].indexOf('\0'));
    }

    return outDict;
  }

  structEncode(props) {
    const encStream = new rs.EncodeStream();
    const promise = new Promise(function (resolve, reject) {
      encStream.pipe(
        concat(function (buf) {
          resolve(buf);
        })
      );
      encStream.on('error', function (err) {
        reject(err);
      });
    });

    this.schema.encode(encStream, props);
    encStream.end();
    return promise;
  }

  async pack(unpackedArg) {
    let argCopy = {};

    for (const key in unpackedArg) {
      if (typeof unpackedArg[key] === 'string') {
        argCopy[key] = Buffer.alloc(this.schema.fields[key].length);
        argCopy[key].write(unpackedArg[key], 'utf-8');
      } else {
        argCopy[key] = unpackedArg[key];
      }
    }

    return await this.structEncode(argCopy);
  }

  print(unpackedArg) {
    return JSON.stringify(unpackedArg, null, 4);
  }
}

export default StructDef;

/* Component for displaying the seat debug log */

import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import hsm from '../../HsManager';
import eolm from '../../EolManager';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '16px',
  },
  header: {
    flex: '1',
    display: 'flex',
  },
  headerText: {
    flex: '1',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.custom.buttonSpacing,
  },
  logMessageContainer: {
    alignItems: 'flex-start',
    background: theme.palette.background.log,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: '16px',
    marginTop: '16px',
    height: '300px',
    overflow: 'auto',
  },
  logMessage: {
    fontFamily: 'monospace',
    fontSize: '14px',
    lineHeight: '1.4',
  },
  logMessageDebug: {
    color: theme.palette.text.logDebug,
  },
  logMessageInfo: {
    color: theme.palette.text.logInfo,
  },
  logMessageWarning: {
    color: theme.palette.text.logWarn,
  },
  logMessageError: {
    color: theme.palette.text.logError,
  },
  logMessagePanic: {
    color: theme.palette.text.logPanic,
  },
}));

/* component representing the log header and action buttons */
function LogHeader(props) {
  const styles = useStyles();

  return (
    <div className={styles.header}>
      <Typography variant='h4' align='left' className={styles.headerText}>
        Log
      </Typography>
      <div className={styles.buttonContainer}>
        <Button variant='contained' color='primary' onClick={props.onClear}>
          Clear
        </Button>
        <Button variant='contained' color='primary' onClick={props.onDownload}>
          Save
        </Button>
        <Button variant='contained' color='primary' onClick={props.onCopy}>
          Copy
        </Button>
      </div>
    </div>
  );
}

/* component for the entire log */
function Log(props) {
  const styles = useStyles();
  const [hsLog, setHsLog] = useState([]);

  useEffect(() => {
    /* maintain the log based on emitted userAction and hsLogMsg and testStatus events */
    function onHsLogMsg(hsLogMsg) {
      setHsLog((oldLog) => [...oldLog, hsLogMsg]);
    }

    function onTestStatus(testStatus) {
      const logMsg = {
        level: 'TEST ',
        msg: `${testStatus.step}) ${testStatus.statusMsg}` /* matches formatting from TestRunner */,
      };
      setHsLog((oldLog) => [...oldLog, logMsg]);
    }

    function onUserAction(userAction) {
      if (userAction.running) {
        const logMsg = {
          level: 'USER ',
          msg: `user requested '${userAction.name}'`,
        };
        setHsLog((oldLog) => [...oldLog, logMsg]);
      }
    }

    hsm.registerEventHandler('hsLogMsg', onHsLogMsg);
    eolm.registerEventHandler('testStatus', onTestStatus);
    hsm.registerEventHandler('userAction', onUserAction);
    eolm.registerEventHandler('userAction', onUserAction);
    return () => {
      hsm.unregisterEventHandler('hsLogMsg', onHsLogMsg);
      eolm.unregisterEventHandler('testStatus', onTestStatus);
      hsm.unregisterEventHandler('userAction', onUserAction);
      eolm.unregisterEventHandler('userAction', onUserAction);
    };
  }, []);

  function getLogLineClassName(entry) {
    let className = styles.logMessage;

    if (entry.level === 'DEBUG') className += ` ${styles.logMessageDebug}`;
    else if (entry.level === 'INFO ') className += ` ${styles.logMessageInfo}`;
    else if (entry.level === 'WARN ') className += ` ${styles.logMessageWarning}`;
    else if (entry.level === 'ERROR') className += ` ${styles.logMessageError}`;
    else if (entry.level === 'PANIC') className += ` ${styles.logMessagePanic}`;

    return className;
  }

  /* helper for parsing a log entry into a string */
  function getLogLineString(entry) {
    /* print with timestamps if this message has one */
    return entry.timestamp ? `[${entry.level}] ${entry.timestamp} | ${entry.msg}` : `[${entry.level}] ${entry.msg}`;
  }

  /* helper for converting the log to a string */
  function assembleLogData() {
    return hsLog.map((entry) => `${getLogLineString(entry)}\n`).join('');
  }

  /* copy the log to the clipboard */
  function copyLog() {
    const data = assembleLogData();
    navigator.clipboard.writeText(data);
  }

  function getDateString() {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const second = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day}_${hour}-${minute}-${second}`;
  }

  /* download the log as a file */
  function downloadLog() {
    const filename = `${getDateString()}_hslog.txt`;
    const type = 'text/plain';
    const data = assembleLogData();

    const file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
      const a = document.createElement('a'),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  /* clear the log */
  function clearLog() {
    setHsLog([]);
  }

  return (
    <div className={styles.root}>
      <LogHeader onClear={clearLog} onDownload={downloadLog} onCopy={copyLog} />
      <div className={styles.logMessageContainer}>
        <div>
          {hsLog.map((entry, i) => (
            <div key={i} className={getLogLineClassName(entry)}>
              {getLogLineString(entry)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Log;

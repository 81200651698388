import ParamDef from './ParamDef';

/* type representing a protobuf parameter / return data (requires supporting protobuf code) */
class ProtobufDef extends ParamDef {
  constructor(schema) {
    super();
    this.schema = schema;
  }

  async unpack(buf) {
    return this.schema.deserializeBinary(buf).toObject();
  }

  async pack(unpackedArg) {
    /* no commands currently take protobufs as input parameters */
    throw new Error('protobuf packing');
  }

  print(unpackedArg) {
    return JSON.stringify(unpackedArg, null, 4);
  }
}

export default ProtobufDef;

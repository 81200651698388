/* View for allowing the user to log in */

import React, { useState } from 'react';
import { Paper, Typography, Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import eolm from '../../EolManager';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: '250px',
    width: 'auto',
    margin: '48px',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    width: '400px',
    gap: theme.custom.textFieldSpacing,
  },
  textInput: {
    minWidth: '300px',
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

function LoginView(props) {
  const styles = useStyles();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  /* called when the user attempts to log in */
  function onSubmitButton() {
    eolm.logInEmitErr(userName, password);
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  return (
    <div className={styles.root}>
      <img src={process.env.PUBLIC_URL + 'casana-logo.svg'} className={styles.logo} alt='logo' />
      <Paper>
        <div className={styles.loginContainer} onKeyPress={handleKeyPress}>
          <Typography variant='h4' align='left'>
            Login
          </Typography>
          <TextField
            className={styles.textInput}
            variant='outlined'
            size='small'
            label='Username'
            onChange={(event) => setUserName(event.target.value)}
            value={userName}
          />
          <TextField
            className={styles.textInput}
            variant='outlined'
            size='small'
            label='Password'
            type='password'
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
          <div className={styles.bottomBar}>
            <Button variant='contained' color='primary' onClick={onSubmitButton}>
              Submit
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default LoginView;

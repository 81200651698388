/* Component for displaying the most up-to-date user config from the seat */

import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import hsm from '../../HsManager';
import HsDefaultUserConfig from '../../lib/HsDefaultUserConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: '16px',
  },
}));

function Config(props) {
  const styles = useStyles();
  const [hsUserConfig, setUserHsConfig] = useState(HsDefaultUserConfig);

  useEffect(() => {
    /* maintain the hsUserConfig state */
    function onHsUserConfig(newHsUserConfig) {
      setUserHsConfig(newHsUserConfig);
    }

    hsm.registerEventHandler('hsUserConfig', onHsUserConfig);
    return () => {
      hsm.unregisterEventHandler('hsUserConfig', onHsUserConfig);
    };
  }, []);

  /* returns the display name for a config variable */
  function nameString(name) {
    if (name === 'wifi_ssid') return 'Wifi SSID';
    else if (name === 'wifi_pass') return 'Wifi Password';

    return name
      .replace('_', ' ')
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      .replace(/\s./, function (str) {
        return str.toUpperCase();
      });
  }

  /* returns the display string for a config variable value */
  function valueString(name, value) {
    if (name === 'wifi_pass' && value !== '') return '*****';
    else return value.toString();
  }

  return (
    <div className={styles.root}>
      <Typography variant='h4' align='left'>
        Configuration
      </Typography>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell width='30%' align='left'>
              Name
            </TableCell>
            <TableCell align='left'>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(hsUserConfig).map((kv) => (
            <TableRow key={kv[0]}>
              <TableCell>{nameString(kv[0])}</TableCell>
              <TableCell>{valueString(kv[0], kv[1])}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Config;

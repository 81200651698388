import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';
import DialogStatusText from '../basic/DialogStatusText';
import hsm from '../../HsManager';

const useStyles = makeStyles((theme) => ({
  textInput: {
    marginTop: theme.custom.textFieldSpacing,
    minWidth: '400px',
  },
  bottomBar: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    paddingLeft: '24px',
  },
  statusText: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
}));

function BleAuthDialog(props) {
  const styles = useStyles();
  const [bleConfig, setBleConfig] = useState({ authToken: '' });
  const [statusMsg, setStatusMsg] = useState('');
  const [statusMsgIsError, setStatusMsgIsError] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  function onAuthTokenChanged(newToken) {
    setBleConfig({ authToken: newToken });
  }

  function onSubmitButton() {
    props.onSubmit(bleConfig);
  }

  function onCloseButton() {
    props.onClose();
    setStatusMsg('');
    setStatusMsgIsError(false);
    setBleConfig({ authToken: '' });
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  useEffect(() => {
    /* maintain the status of the connection authorization process */
    function onBleAuthConnectStatus(bleAuthConnectStatus) {
      setStatusMsg(bleAuthConnectStatus.statusMsg);
      setStatusMsgIsError(!!bleAuthConnectStatus.error);
      setButtonsDisabled(bleAuthConnectStatus.isRunning);
    }

    hsm.registerEventHandler('bleAuthConnect', onBleAuthConnectStatus);
    return () => {
      hsm.unregisterEventHandler('bleAuthConnect', onBleAuthConnectStatus);
    };
  }, []);

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton}>
      <DialogTitle>Connect with Bluetooth</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter your seat's 6 digit Seat PIN.</DialogContentText>
        <TextField
          className={styles.textInput}
          variant='outlined'
          size='small'
          label='Auth Token'
          onChange={(event) => onAuthTokenChanged(event.target.value)}
          value={bleConfig['authToken']}
        />
      </DialogContent>
      <div className={styles.bottomBar}>
        <DialogStatusText className={styles.statusText} isError={statusMsgIsError}>
          {statusMsg}
        </DialogStatusText>
        <DialogActions>
          <Button disabled={buttonsDisabled} onClick={onCloseButton}>
            Close
          </Button>
          <Button disabled={buttonsDisabled} onClick={onSubmitButton}>
            Submit
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default BleAuthDialog;

/* Helper component representing an editable string in a table */

import { TableCell, TextField } from '@mui/material';

function EditableStringTableCell(props) {
  return (
    <TableCell>
      <TextField variant='outlined' size='small' type='number' onChange={props.onChange} value={props.value} />
    </TableCell>
  );
}

export default EditableStringTableCell;

/* Flow for forcing checkin with uploads */

import hsm from '../../HsManager';
import { useUserConfig, useFirmwareVersion } from '../../hsHooks';
import BasicFlow from './BasicFlow';
import semver from 'semver';

function ForceUploadFlow(props) {
  /* this button is disabled if no wifi SSID is configured */
  const userConfig = useUserConfig();
  const fwVersion = useFirmwareVersion();
  const disabled = props.disabled || (fwVersion && semver.lt(fwVersion, '0.18.0')) || !userConfig.wifi_ssid || userConfig.wifi_ssid === '';

  return <BasicFlow disabled={disabled} className={props.className} label='Force Upload' onClick={() => hsm.forceUploadUI()} />;
}
export default ForceUploadFlow;

/* Component for running the EoL test and displaying status */

import React, { useEffect, useState } from 'react';
import { Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import eolm from '../../EolManager';
import { useBleConnStatus, useHsStatus } from '../../hsHooks';
import HsConnStatus from '../../lib/HsConnStatus';
import BleConnectFlow from '../flows/BleConnectFlow';
import TestConfigFlow from '../flows/TestConfigFlow';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '16px',
  },
  testStatusConfigContainer: {
    display: 'flex',
  },
  testStatusContainer: {
    display: 'flex',
    flex: '1',
  },
  testConfigContainer: {
    display: 'flex',
    flex: '1',
  },
  header: {
    display: 'flex',
  },
  headerText: {
    flex: '1',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.custom.buttonSpacing,
  },
  serialText: {
    alignItems: 'center',
    marginRight: theme.custom.buttonSpacing,
  },
  logMessageContainer: {
    alignItems: 'flex-start',
    background: theme.palette.background.log,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column-reverse',
    padding: '16px',
    marginTop: '16px',
    height: '600px',
    overflow: 'auto',
  },
  logMessage: {
    fontFamily: 'monospace',
    fontSize: '14px',
    lineHeight: '1.4',
  },
  logMessageError: {
    color: theme.palette.text.logPanic,
  },
  logMessageSuccess: {
    color: theme.palette.text.logSuccess,
  },
}));

function TestRunner(props) {
  const styles = useStyles();
  const bleConnStatus = useBleConnStatus();
  const hsStatus = useHsStatus();
  const [testConfig, setTestConfig] = useState({ ...window.APP_CONFIG.eol.testConfigs });
  const [testLog, setTestLog] = useState([]);
  const [testRunning, setTestRunning] = useState(false);
  const [abortDisabled, setAbortDisabled] = useState(false);

  /* called when the start / stop test button is clicked */
  function onTestButton() {
    if (testRunning) {
      setAbortDisabled(true);
      eolm.abortTest();
    } else {
      setTestLog([]);
      eolm.runTestUI(testConfig);
    }
  }

  /* helper function for getting a log level string */
  function getLogLevel(testStatus) {
    if (testStatus.succeeded) return 'PASS ';
    else if (testStatus.error !== null) return 'ERROR';
    else return 'TEST ';
  }

  useEffect(() => {
    /* maintain the status of the EoL test and log status events */
    function onTestStatus(testStatus) {
      const logMsg = {
        msg: testStatus.statusMsg,
        step: testStatus.step,
        level: getLogLevel(testStatus),
      };
      setTestLog((oldLog) => [...oldLog, logMsg]);
      setTestRunning(testStatus.isRunning);

      /* re-enable the ability to abort a test whenever a test stops */
      if (!testStatus.isRunning) setAbortDisabled(false);
    }

    eolm.registerEventHandler('testStatus', onTestStatus);

    /*
     * We don't have a way to access the EoL tester what position
     * it is currently in, so we reset it to position 0 with no
     * test signals running every time the page is reloaded.
     */
    eolm.resetTesterUI(0);

    return () => {
      eolm.unregisterEventHandler('testStatus', onTestStatus);
    };
  }, []);

  /* get the classname given a log entry */
  function getLogLineClassName(entry) {
    let className = styles.logMessage;

    if (entry.level === 'PASS ') className += ` ${styles.logMessageSuccess}`;
    else if (entry.level === 'ERROR') className += ` ${styles.logMessageError}`;

    return className;
  }

  /* helper for parsing a log entry into a string */
  function getLogLineString(entry) {
    return `[${entry.level}] ${entry.step}) ${entry.msg}`;
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography variant='h4' align='left' className={styles.headerText}>
          Test Status
        </Typography>
        <div className={styles.buttonContainer}>
          <Typography variant='h6' align='right' className={styles.serialText}>
            {hsStatus ? `Serial: ${hsStatus.serialNumber}` : ''}
          </Typography>
          {props.showConnect ? <BleConnectFlow connMethod='scan' /> : null}
          {props.showConfig ? <TestConfigFlow currentTestConfig={testConfig} onSubmit={(config) => setTestConfig(config)} /> : null}
          <Button
            disabled={abortDisabled || bleConnStatus !== HsConnStatus.CONNECTED}
            variant='contained'
            color={testRunning ? 'secondary' : 'primary'}
            onClick={onTestButton}
          >
            {testRunning ? 'Abort Test' : 'Start Test'}
          </Button>
        </div>
      </div>
      <div className={styles.logMessageContainer}>
        <div>
          {testLog.map((entry, i) => (
            <div key={i} className={getLogLineClassName(entry)}>
              {getLogLineString(entry)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TestRunner;

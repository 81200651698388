import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditableNumericTableCell from '../basic/EditableNumericTableCell';

const useStyles = makeStyles((theme) => ({
  ppgCurrentTable: {
    marginBottom: '8px',
  },
  otherValueTable: {
    marginTop: '8px',
  },
}));

function TestConfigDialog(props) {
  const styles = useStyles();
  const [testConfig, setTestConfig] = useState({ ...props.currentTestConfig });

  /* called when a PPG current value changes */
  function onPpgCurrentChanged(name, i, newVal) {
    const newInt = parseInt(newVal) || 0;

    const newPpgObj = { ...testConfig.ppgCurrentsMa[i] };
    newPpgObj[name] = newInt;

    const newPpgCurrents = [...testConfig.ppgCurrentsMa];
    newPpgCurrents[i] = newPpgObj;

    setTestConfig({ ...testConfig, ppgCurrentsMa: newPpgCurrents });
  }

  /* changed when a top-level numeric config is changed */
  function onNumericValueChanged(name, newVal) {
    const newInt = parseInt(newVal) || 0;
    const newConfig = { ...testConfig };
    newConfig[name] = newInt;
    setTestConfig(newConfig);
  }

  /* called when the submit button is clicked */
  function onSubmitButton() {
    props.onSubmit(testConfig);
  }

  /* called when the dialog is dismissed */
  function onCloseButton() {
    /* reset to whatever config was there before the user opened the dialog */
    setTestConfig(props.currentTestConfig);
    props.onClose();
  }

  /* called to reset the test config to the default values from testConfig */
  function onResetDefaultButton() {
    setTestConfig({ ...window.APP_CONFIG.eol.testConfigs });
  }

  /* hotkey helper */
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmitButton();
    }
  }

  return (
    <Dialog open={props.open} onKeyPress={handleKeyPress} onClose={onCloseButton} fullWidth>
      <DialogTitle>Test Configuration</DialogTitle>
      <DialogContent>
        <Table size='small' className={styles.ppgCurrentTable}>
          <TableHead>
            <TableRow>
              <TableCell width='30%' align='left'>
                Name
              </TableCell>
              <TableCell align='left'>PPG Red (mA)</TableCell>
              <TableCell align='left'>PPG IR (mA)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testConfig.ppgCurrentsMa.map((ppgObj, i) => (
              <TableRow key={i}>
                <TableCell>{`Recording ${i + 1}`}</TableCell>
                <EditableNumericTableCell onChange={(event) => onPpgCurrentChanged('red', i, event.target.value)} value={ppgObj.red} />
                <EditableNumericTableCell onChange={(event) => onPpgCurrentChanged('ir', i, event.target.value)} value={ppgObj.ir} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Table size='small' className={styles.otherValueTable}>
          <TableHead>
            <TableRow>
              <TableCell width='40%' align='left'>
                Name
              </TableCell>
              <TableCell align='left'>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{'Generic Delay (ms)'}</TableCell>
              <EditableNumericTableCell
                onChange={(event) => onNumericValueChanged('genericDelayMs', event.target.value)}
                value={testConfig['genericDelayMs']}
              />
            </TableRow>
            <TableRow>
              <TableCell>{'Checkin Delay (ms)'}</TableCell>
              <EditableNumericTableCell
                onChange={(event) => onNumericValueChanged('checkinDelayMs', event.target.value)}
                value={testConfig['checkinDelayMs']}
              />
            </TableRow>
            <TableRow>
              <TableCell>{'Weight Test Delay (ms)'}</TableCell>
              <EditableNumericTableCell
                onChange={(event) => onNumericValueChanged('weightTestDelayMs', event.target.value)}
                value={testConfig['weightTestDelayMs']}
              />
            </TableRow>
            <TableRow>
              <TableCell>{'Initial Test Duration (ms)'}</TableCell>
              <EditableNumericTableCell
                onChange={(event) => onNumericValueChanged('initialTestDurationMs', event.target.value)}
                value={testConfig['initialTestDurationMs']}
              />
            </TableRow>
            <TableRow>
              <TableCell>{'PPG Test Duration (ms)'}</TableCell>
              <EditableNumericTableCell
                onChange={(event) => onNumericValueChanged('ppgTestDurationMs', event.target.value)}
                value={testConfig['ppgTestDurationMs']}
              />
            </TableRow>
            <TableRow>
              <TableCell>{'IMP Test Duration (ms)'}</TableCell>
              <EditableNumericTableCell
                onChange={(event) => onNumericValueChanged('impTestDurationMs', event.target.value)}
                value={testConfig['impTestDurationMs']}
              />
            </TableRow>
            <TableRow>
              <TableCell>{'ECG Test Duration (ms)'}</TableCell>
              <EditableNumericTableCell
                onChange={(event) => onNumericValueChanged('ecgTestDurationMs', event.target.value)}
                value={testConfig['ecgTestDurationMs']}
              />
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseButton}>Close</Button>
        <Button onClick={onResetDefaultButton}>Reset Default</Button>
        <Button onClick={onSubmitButton}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default TestConfigDialog;

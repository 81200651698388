/* Root Level Application component */

/*
 * This file contains the root-level component for this react app.
 * It is responsible for:
 *  - displaying static content that is on every page (header, user info, etc)
 *  - routing to the individual Views
 *  - acting as an error boundary for the rest of the application
 *  - supplying the material-ui theme for all components
 *
 * We use a class-style component here specifically so we have access
 * to react's native error boundaries.
 */

import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import hsm from '../HsManager';
import eolm from '../EolManager';
import EolUserLevel from '../EolUserLevel';
import { themeCommon, darkTheme, lightTheme } from './theme';
import DarkModeToggle from './persistent/DarkModeToggle';
import VersionWarning from './persistent/VersionWarning';
import ErrorAlert from './persistent/ErrorAlert';
import UserInfo from './persistent/UserInfo';
import LoginView from './views/LoginView';
import EngineerView from './views/EngineerView';
import AdminView from './views/AdminView';
import EndUserView from './views/EndUserView';
import MainView from './views/MainView';

const styles = {
  app: {
    flex: '1',
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
  topBarContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userInfoContainer: {
    display: 'flex',
    gap: themeCommon.custom.paperSpacing,
  },
  appLogo: {
    height: '30px',
    pointerEvents: 'none',
    marginLeft: '12px',
  },
  appContainer: {
    flexBasis: '1600px',
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1600px',
    gap: themeCommon.custom.paperSpacing,
  },
};

/* get the initial theme based on previous user preferences */
function getInitialTheme() {
  try {
    const savedPreference = JSON.parse(localStorage.getItem('theme'));
    if (savedPreference === 'light') return lightTheme;
    else return darkTheme;
  } catch (err) {
    /* default to dark theme if no value or an invalid value is found */
    localStorage.removeItem('theme');
    return darkTheme;
  }
}

/* root element of the entire application */
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: getInitialTheme(),
      error: null,
      userToken: eolm.getUserToken(),
    };

    this.onDarkModeToggle = this.onDarkModeToggle.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onUserToken = this.onUserToken.bind(this);
    this.onError = this.onError.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
  }

  /* called when the user toggles dark mode by clicking the DarkModeToggle */
  onDarkModeToggle() {
    this.setState((state) => {
      if (state.theme === darkTheme) {
        localStorage.setItem('theme', JSON.stringify('light'));
        return { theme: lightTheme };
      } else {
        localStorage.setItem('theme', JSON.stringify('dark'));
        return { theme: darkTheme };
      }
    });
  }

  /* see react error boundaries */
  static getDerivedStateFromError(error) {
    return { error };
  }

  /* called when a generic error is emitted */
  onError(error) {
    this.setState({ error });
  }

  /* called when the user dismisses an error message */
  onErrorClose() {
    this.setState({ error: null });
  }

  /* called when a user logs in or out */
  onUserToken(token) {
    this.setState({ userToken: token, error: null });
  }

  /* called when the user logs out */
  onLogout() {
    this.onErrorClose();
    eolm.logOutEmitErr();
  }

  /* called after the react component is initialized */
  componentDidMount() {
    /* register event handlers for login / logout and generic error events */
    hsm.registerEventHandler('error', this.onError);
    eolm.registerEventHandler('error', this.onError);
    eolm.registerEventHandler('userToken', this.onUserToken);
  }

  /* called before the react component is destroyed */
  componentWillUnmount() {
    /* unregister event handlers for login / logout and generic error events */
    hsm.unregisterEventHandler('error', this.onError);
    eolm.unregisterEventHandler('error', this.onError);
    eolm.unregisterEventHandler('userToken', this.onUserToken);
  }

  /* Gets the view that should be displayed to the user. This acts as a router */
  getActiveView() {
    if (window.APP_CONFIG.isBle) return <MainView />;

    if (!this.state.userToken) return <LoginView />;
    else if (this.state.userToken.level === EolUserLevel.ENGINEER) return <EngineerView />;
    else if (this.state.userToken.level === EolUserLevel.ADMIN) return <AdminView />;
    else if (this.state.userToken.level === EolUserLevel.USER) return <EndUserView />;
    else return <LoginView />;
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={this.state.theme}>
          <CssBaseline />
          <div className={this.props.classes.app}>
            <div className={this.props.classes.appContainer}>
              <div className={this.props.classes.topBarContainer}>
                <img
                  src={`${process.env.PUBLIC_URL}${this.state.theme.img.logoSvgName}`}
                  className={this.props.classes.appLogo}
                  alt='logo'
                />
                <div className={this.props.classes.userInfoContainer}>
                  <DarkModeToggle theme={this.state.theme} onToggle={this.onDarkModeToggle} />
                  <UserInfo userToken={this.state.userToken} onLogout={this.onLogout} />
                </div>
              </div>
              <VersionWarning />
              <ErrorAlert detailsVisible={true} error={this.state.error} onErrorClose={this.onErrorClose} />
              {this.getActiveView()}
            </div>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default withStyles(styles)(App);

/* Flow for uploading a new operating config */

import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useBleConnStatus, useHsUserActionRunning, useHsStatus, useUserConfig, useOpConfig } from '../../hsHooks';
import eolm from '../../EolManager';
import HsConnStatus from '../../lib/HsConnStatus';
import OpConfigDialog from '../dialogs/OpConfigDialog';
import InfoDialog from '../dialogs/InfoDialog';

function OpConfigFlow(props) {
  const bleConnStatus = useBleConnStatus();
  const userActionRunning = useHsUserActionRunning();
  const opConfig = useOpConfig();
  const userConfig = useUserConfig();
  const hsStatus = useHsStatus();
  const [opConfigDialogOpen, setOpConfigDialogOpen] = useState(false);
  const [opSuccessDialogOpen, setOpSuccessDialogOpen] = useState(false);
  const disabled =
    props.disabled || userActionRunning || bleConnStatus !== HsConnStatus.CONNECTED || !userConfig.wifi_ssid || userConfig.wifi_ssid === '';

  useEffect(() => {
    /* maintain the status of the upload operation */
    function onUploadOpConfigStatus(uploadOpConfigStatus) {
      /* close the config dialog on success and display the success dialog */
      if (uploadOpConfigStatus.succeeded) {
        setOpConfigDialogOpen(false);
        setOpSuccessDialogOpen(true);
      }
    }

    eolm.registerEventHandler('uploadOpConfigStatus', onUploadOpConfigStatus);
    return () => {
      eolm.unregisterEventHandler('uploadOpConfigStatus', onUploadOpConfigStatus);
    };
  }, []);

  return (
    <>
      <Button
        className={props.className}
        disabled={disabled}
        variant='contained'
        color='primary'
        onClick={() => setOpConfigDialogOpen(true)}
      >
        Configure
      </Button>
      <OpConfigDialog
        opConfig={opConfig}
        open={bleConnStatus === HsConnStatus.CONNECTED && opConfigDialogOpen}
        onClose={() => setOpConfigDialogOpen(false)}
        onSubmit={(opConfig) => eolm.uploadOpConfigUI(hsStatus['serialNumber'], opConfig)}
      />
      <InfoDialog
        open={bleConnStatus === HsConnStatus.CONNECTED && opSuccessDialogOpen}
        onClose={() => setOpSuccessDialogOpen(false)}
        message='Operating Config Uploaded!'
      />
    </>
  );
}

export default OpConfigFlow;
